import React, { useState, useEffect, useRef } from "react";
import Tags from "../Tags";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { update } from "immutable";
import Swal from "sweetalert2";
import {
  freezeWindow,
  unfreezeWindow,
} from "../../../../../src/HelperFunctions/Overlay";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

import {
  createRole,
  updateRole,
  getRole,
  deleteRole,
} from "../../../../store/actions/roleAction";
import {
  createProject,
  getProject,
} from "../../../../store/actions/projectAction";
import {
  createCircle,
  getCircle,
} from "../../../../store/actions/circleAction";
import { useAlert } from "react-alert";
import {
  ROLE_SUCCESS_MESSAGE_CLEAR,
  ROLE_ERROR_CLEAR,
  ROLE_ID_CLEAR,
} from "../../../../store/types/roleType";
import {
  SKILL_ID_CLEAR,
  SKILL_SUCCESS_MESSAGE_CLEAR,
} from "../../../../store/types/skillType";
import { SERVER_URI } from "../../../../config/keys";
import { TAGS_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/tagsType";
import { getTags, tagCreate } from "../../../../store/actions/tagsAction";
import { updateState, getState } from "../../../../store/actions/stateAction";
import {
  historyGet,
  historyCreate,
} from "../../../../store/actions/historyAction";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../../store/types/historyType";
import {
  updateMember,
  getMember,
} from "../../../../store/actions/memberAction";
import { createSkills, getSkills } from "../../../../store/actions/skillAction";
import {
  updateDomain,
  getDomain,
  createDomain,
} from "../../../../store/actions/domainAction";
import CreatableSelect from "react-select/creatable";

import {
  DOMAIN_SUCCESS_MESSAGE_CLEAR,
  DOMAIN_ERROR_CLEAR,
  DOMAIN_ID_CLEAR,
} from "../../../../store/types/domainType";
import Objectives from "./Objectives";
import Tasks from "./Tasks";
import WorkLoadSRDL from "./WorkLoadSRDL";
import { sumFTE } from "../../../../HelperFunctions/FTE_cal";
import { numberInputOnWheelPreventChange } from "../../../../HelperFunctions/StopInputScroll";
import DefaultRoleOwners from "./DefaultRoleOwners";
import Documents from "./Documents";
import { GetAliasesName } from "../../../../HelperFunctions/GetAliasesName";

export default function RoleModal({
  data,
  closeRole,
  CloseState,
  stateCloseState,
  setRoles,
  isNToP,
  focusMode,
  compareMode,
  teams,
  setPurposetreeLoader
}) {
  const animatedComponents = makeAnimated();
  let alert = useAlert();
  let dispatch = useDispatch();
  const selectInputRefs = useRef([]);

  const { myInfo } = useSelector((state) => state.auth);
  const { roles, cuRoleId, nDaoamins, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );
  // console.log(roles)
  const { skills, cuSkillId, skillsMessagee, skillError, skillsMessage } =
    useSelector((state) => state.skills);
  const { domains, dDomains, domainMessage, domainError } = useSelector(
    (state) => state.domains
  );
  const { peoples, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { states, cuStateId, statesMessagee, statesError, statesMessage } =
    useSelector((state) => state.states);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/role/create`;
  let urls = `${SERVER_URI}/user/role/update`;

  const [documentArray, setDocumentData] = React.useState(null);
  // console.log(documentArray);
  // console.log(teamId)
  // current Role member data get


  let memberIds = [];
  if (data) {
    members.map((e, index) => {
      let inc = e.coreMembers.Roles.includes(data._id);
      let incs = e.extendedMembers.Roles.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, XTD: "" });
      }
      if (incs) {
        memberIds.push({ memberId: e.memberType, XTD: "XTD" });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + `(${GetAliasesName(teams,"Circles").toLowerCase()})`/* "(circle)" */;
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + `(${GetAliasesName(teams,"Projects").toLowerCase()})`/* "(project)" */;
        }
      }
    }
  }

  // Current role members action

  const cFRolesSub = (data, popoverId, type) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Roles
    ) {
      let inc = popoverId.coreMembers.Roles.includes(data._id);
      if (inc) {
        popoverId.coreMembers.Roles = popoverId.coreMembers.Roles.filter(
          (e) => {
            return e !== data._id;
          }
        );
      }
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Roles
    ) {
      let incs = popoverId.extendedMembers.Roles.includes(data._id);
      if (incs) {
        popoverId.extendedMembers.Roles =
          popoverId.extendedMembers.Roles.filter((e) => {
            return e !== data._id;
          });
      }
    }
    if (popoverId) {
      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/update`;
      // console.log(popoverId)
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        // dispatch(getMember(obj));
      });
    }
  };

  const memberHandel = (elements) => {
    let popoverId = {};
    if (elements && elements.memberId.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === elements.memberId) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };
          if (arr && arr.teamId.length > 0) {
            // setPopoverId(arr)
            popoverId = arr;
          }
          break;
        }
      }
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD === "XTD"
    ) {
      cFRolesSub(role, popoverId, "extend");
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD !== "XTD"
    ) {
      cFRolesSub(role, popoverId, "core");
    }
  };

  // set role create and update information

  let arr = {
    _id: data && data._id ? data._id : null,
    teamId: data && data.teamId ? data.teamId : teamId,
    roleName: data && data.roleName ? data.roleName : "",
    defaultRole: data && data.defaultRole ? data.defaultRole : false,
    purpose: data && data.purpose ? data.purpose : "",
    tasks: data && data.tasks ? data.tasks : [],
    objectives: data && data.objectives ? data.objectives : [],
    ownerRole: data && data.ownerRole ? data.ownerRole : "Multiple owners",
    owners: data && data.owners ? data.owners : [],
    domains: data && data.domains ? data.domains : [],
    memberIn: data && data.memberIn ? data.memberIn : [],
    tags: data && data.tags ? data.tags : [],
    newTag: [],
    adminId: adminId ? adminId : null,
    url: urls ? urls : "",
    planTotal: data && data.planTotal ? data.planTotal : "",
    workload: data && data.workload ? data.workload : [],
    notes: data && data.notes ? data.notes : "",
  };

  // set current role data in useState and change sate

  const [role, setRole] = useState(arr);

  const handleChange = (e) => {
    // console.log(e.target.name)
    if (e.target.name === "ownerRole" && e.target.value === "Multiple owners") {
      Swal.fire({
        title: "Are you sure?",
        text: "This will reset the lead of circles and projects where this role is a lead!",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          setRole({
            ...role,
            [e.target.name]: e.target.value,
          });
        } else {
          /* var singleOwnerRoleCheckbox =
            document.getElementById("single_owner_role");
          singleOwnerRoleCheckbox.checked = true;
          var inputEvent = new Event("input", { bubbles: true });
          singleOwnerRoleCheckbox.dispatchEvent(inputEvent); */
          setRole({
            ...role,
            [e.target.name]: "Single owner",
          });
        }
      });
    }
    // else if (e.target.name === "planTotal") {
    //   setRole({
    //     ...role,
    //     [e.target.name]:
    //       e.target.value === "" ? 0 : e.target.value < 0 ? 0 : e.target.value,
    //   });
    // }
    else {
      setRole({
        ...role,
        [e.target.name]: e.target.value,
      });
    }
  };

  document.addEventListener("input", function (e) {
    if (
      e.target &&
      e.target.type === "radio" &&
      e.target.name === "ownerRole"
    ) {
      handleChange(e);
    }
  });

  //added by ranjit
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const centeredPlaceholderStyle = {
    lineHeight: "3rem",
    height: "2rem",
    padding: "0",
  };

  //
  //options Role
  var Data = [];
  peoples.map((e) => {
    Data.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` });
  });

  //options domains
  var Domains = [];
  domains.map((e) => {
    if (
      e.owners &&
      Object.keys(e.owners).length > 0 &&
      (e.owners.owner === null || !e.owners.owner) &&
      (e.owners.type === null || !e.owners.type)
    ) {
      Domains.push({ value: `${e._id}`, label: `${e.domainName}` });
    }
  });

  dDomains.map((e) => {
    if (
      e.owners &&
      Object.keys(e.owners).length > 0 &&
      (e.owners.owner === null || !e.owners.owner) &&
      (e.owners.type === null || !e.owners.type)
    ) {
      Domains.push({ value: `${e._id}`, label: `${e.domainName}` });
    }
  });

  const options = Data;

  // set current owners data for options



  var cumentor = [];
  if (data && data.owners) {
    if (data && data.owners.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        let inc = arr.owners.includes(peoples[i]._id);
        if (inc) {
          cumentor.push({
            value: `${peoples[i]._id}`,
            label: `${peoples[i].fname} ${peoples[i].lname}`,
          });
        }
      }
    }
  }

  const [value, setValue] = React.useState(cumentor);

  useEffect(() => {
    var ownersData = [];
    if (role.ownerRole === "Single owner") {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          ownersData.push(value[0].value);
        }
      } else {
        if (value) {
          ownersData.push(value.value);
        }
      }
    } else if (role.ownerRole === "Multiple owners") {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          ownersData.push(value[i].value);
        }
      }
    }
    if (ownersData) {
      setRole({
        ...role,
        ["owners"]: ownersData,
      });
    }
  }, [value, /*setValue */]);
  // console.log(value);

  const [domainOwners, setDomainOwners] = useState([]);

  const handelSelect = (value) => {
    if (value === null) {
      setValue([]);
      return;
    }
    let allRoleOwnerIds = value && Array.isArray(value) ? value.map((sv) => sv.value) : [value].map((sv) => sv.value);

    // Function to filter domainsValue
    const updatedDomainsValue = domainsValue.map(domainItem => {
      if (!allRoleOwnerIds.includes(domainItem.owner.value)) {
        return {
          ...domainItem,
          owner: { value: null, label: null }
        };
      }
      return domainItem;
    });
    setDomainsValue(updatedDomainsValue);

    if (role.ownerRole === "Single owner") {
      let mDatas = [];
      if (value) {
        if (Data && Data.length > 0) {
          for (let j = 0; j < Data.length; j++) {
            if (value.value === Data[j].value) {
              mDatas.push(Data[j]);
              break;
            }
          }
        }
      }
      setValue(mDatas);
    } else {
      let mDatas = [];
      if (value) {
        if (value && value.length > 0 && Data && Data.length > 0) {
          for (let i = 0; i < value.length; i++) {
            for (let j = 0; j < Data.length; j++) {
              if (value[i].value === Data[j].value) {
                mDatas.push(Data[j]);
                break;
              }
            }
          }
        }
      }
      setValue(mDatas);
    }

    // console.log(355, value);
    if (value && value.length > 0) {
      // console.log(357, value);
      let domainOwners = [];
      peoples.map((e) => {
        let inc = value.some((item) => item.value === e._id);
        if (inc) {
          domainOwners.push({
            value: `${e._id}`,
            label: `${e.fname} ${e.lname}`,
          });
        }
      });
      setDomainOwners(domainOwners);
    } else {
      // console.log(370, value);
      setDomainOwners({});
    }
  };

  useEffect(() => {
    //domain owner options
    /* if (role.owners) {
      let domainOwners = [];
      peoples.map((e) => {
        let inc = role.owners.includes(e._id);
        if (inc) {
          domainOwners.push({
            value: `${e._id}`,
            label: `${e.fname} ${e.lname}`,
          });
        }
      });
      setDomainOwners(domainOwners)
    } */

    if (value && value.length > 0) {
      let domainOwners = [];
      peoples.map((e) => {
        let inc = value.some((item) => item.value === e._id);
        if (inc) {
          domainOwners.push({
            value: `${e._id}`,
            label: `${e.fname} ${e.lname}`,
          });
        }
      });
      setDomainOwners(domainOwners);
    }
  }, [value, /* setValue */]);

  // set current role domain data for options

  let curDomains = [];

  if (data && data.domains && data.domains.length > 0) {
    for (let i = 0; i < data.domains.length; i++) {
      for (let j = 0; j < domains.length; j++) {
        if (data.domains[i].domain && domains[j]) {
          let inc = [data.domains[i].domain].includes(domains[j]._id);
          if (inc) {
            if (data.domains[i].owner) {
              for (let k = 0; k < peoples.length; k++) {
                let incs = [data.domains[i].owner].includes(peoples[k]._id);
                if (incs) {
                  curDomains.push({
                    domain: {
                      value: domains[j]._id,
                      label: domains[j].domainName,
                    },
                    owner: {
                      value: peoples[k]._id,
                      label: `${peoples[k].fname} ${peoples[k].lname}`,
                    },
                  });
                  break;
                }
              }
            } else {
              curDomains.push({
                domain: {
                  value: domains[j]._id,
                  label: domains[j].domainName,
                },
                owner: {
                  value: "",
                  label: "",
                },
              });
              break;
            }
            break;
          }

          let dDomainFound = dDomains.find(
            (dd) =>
              dd &&
              data &&
              data.domains[j] &&
              data.domains[j].domain &&
              dd._id.toString() === data.domains[j].domain.toString()
          );
          let dDomainOwnerFound = peoples.find(
            (p) =>
              p &&
              data &&
              data.domains[j] &&
              data.domains[j].owner &&
              p._id.toString() === data.domains[j].owner.toString()
          );
          // console.log({dDomainFound, dDomainOwnerFound});

          if (dDomainFound) {
            // console.log({dDomainFound, dDomainOwnerFound});
            curDomains.push({
              domain: {
                value: dDomainFound._id,
                label: dDomainFound.domainName,
              },
              owner: {
                value: dDomainOwnerFound ? dDomainOwnerFound._id : "",
                label: dDomainOwnerFound ? `${dDomainOwnerFound.fname} ${dDomainOwnerFound.lname}` : "",
              },
            });
          }
        }
      }
    }
  }

  if (curDomains.length === 0) {
    curDomains = [
      { domain: { value: "", label: "" }, owner: { value: "", label: "" } },
    ];
  }

  const [domainsValue, setDomainsValue] = React.useState(curDomains);

  // console.log(domainsValue)

  let handleDomainsForm = (i, e) => {
    // console.log(i, e)
    handleDomainsFormClear(i);
    let newFormValues = [...domainsValue];
    newFormValues[i].domain = e;
    setDomainsValue(newFormValues);
  };

  let handleDomainsFormClear = (index) => {
    // console.log(index);
    let updatedValues = [...domainsValue];

    let updateOpDomain = [...opDoamin];
    // console.log(updatedValues);
    if (updatedValues[index] && updatedValues[index].domain) {
      updatedValues[index].domain.value &&
        updateOpDomain.push(updatedValues[index].domain);

      let setData = new Set(updateOpDomain);

      // console.log(491, updatedValues[index].domain, setData);

      setopDoamin(Array.from(setData));

      updatedValues[index].domain = null;
      setDomainsValue(updatedValues);
    }
  };

  let handleOwnerForm = (i, e) => {
    let newFormValues = [...domainsValue];
    newFormValues[i].owner = e;
    setDomainsValue(newFormValues);
  };

  let addFormDomainsFields = () => {
    let len = domainsValue.length;
    if (domainsValue[len - 1].domain.value.length > 0) {
      setDomainsValue([
        ...domainsValue,
        { domain: { value: "", label: "" }, owner: { value: "", label: "" } },
      ]);
    }
  };

  let removeFormDomainsFields = (i) => {
    let newFormDomainValues = [...domainsValue];
    if (domainsValue.length > 1) {
      handleDomainsFormClear(i);
      newFormDomainValues.splice(i, 1);
      setDomainsValue(newFormDomainValues);
    } else if (domainsValue.length == 1) {
      setDomainsValue([
        { domain: { value: "", label: "" }, owner: { value: "", label: "" } },
      ]);
    }
  };

  // set current role domains data owner and domain data

  // console.log(441, role)

  let fDomains = [];
  if (domainsValue.length > 0) {
    for (let i = 0; i < domainsValue.length; i++) {
      if (
        domainsValue[i].domain &&
        domainsValue[i].domain.value &&
        domainsValue[i].domain.value.length > 0 &&
        domainsValue[i].owner
      ) {
        fDomains.push({
          domain: domainsValue[i].domain.value,
          owner:
            domainsValue[i].owner && domainsValue[i].owner.value
              ? domainsValue[i].owner.value
              : null,
        });
      }
      //  console.log("360",domainsValue[i].owner)
    }
  }

  if (fDomains.length > 0) {
    role.domains = fDomains;
  } else {
    role.domains = [{ domain: null, owner: null }];
  }

  // domainOwners

  // let opDoamin = [...Domains];
  const [opDoamin, setopDoamin] = useState();
  useEffect(() => {
    // console.log(563, Domains);

    let newOpDoamin = [...Domains];

    // console.log(newOpDoamin);

    if (Domains.length > 0) {
      for (let i = 0; i < fDomains.length; i++) {
        for (let j = 0; j < newOpDoamin.length; j++) {
          if (fDomains[i].domain === newOpDoamin[j].value) {
            newOpDoamin = newOpDoamin.filter((e) => e !== newOpDoamin[j]);
          }
        }
      }
    }
    // console.log(newOpDoamin);
    setopDoamin(newOpDoamin);
  }, []);

  /* let opDoamin = [...Domains];
  if (Domains.length > 0) {
    for (let i = 0; i < fDomains.length; i++) {
      for (let j = 0; j < opDoamin.length; j++) {
        if (fDomains[i].domain === opDoamin[j].value) {
          // console.log(opDoamin[j])
          opDoamin = opDoamin.filter((e) => e !== opDoamin[j]);
        }
      }
    }
  } */

  // const [values, setValues] = React.useState(cumentor);

  // const [addTaska, setaddTaska] = useState(role.tasks || []);
  // role.tasks = addTaska

  let taskData = [];
  if (role.tasks && role.tasks.length > 0) {
    role.tasks.map((e) => {
      taskData.push({ tasks: e });
    });
    taskData.push({ tasks: "" });
  }

  if (taskData.length === 0) {
    taskData.push({ tasks: "" });
  }

  // sate tasks value for current role

  const [formValues, setFormValues] = useState(taskData);

  let handleForm = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    let len = formValues.length;
    if (formValues[len - 1].tasks.length > 0) {
      setFormValues([...formValues, { tasks: "" }]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [isClicking, setIsCliking] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormFields(e);
      setIsCliking(true);
    }
  };

  useEffect(() => {
    const textarea = document.getElementById(`task${formValues.length - 1}`);
    if (textarea && isClicking) {
      textarea.focus();
      setIsCliking(false);
    }
  }, [formValues, setFormValues]);
  // Filter new task and set role state accordingly

  let newTasks = [];
  if (formValues.length > 0) {
    formValues.map((e) => {
      if (e.tasks.length > 0) {
        newTasks.push(e.tasks);
      }
    });
  }

  role.tasks = newTasks;

  let curTag = [];
  if (role.newTag.length > 0) {
    for (let i = 0; i < role.newTag.length; i++) {
      curTag.push({ userId: myInfo.userId, tags: [role.newTag[i]] });
    }
  }

  // get current time for history of current role
  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  // set Current rle history for  current role
  let historyState = [];
  if (data && Object.keys(data).length > 0) {
    if (data.roleName || role.roleName) {
      if (data.roleName !== role.roleName) {
        if (
          data.roleName &&
          role.roleName &&
          data.roleName.length > 0 &&
          role.roleName.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Role Name",
            prev: data.roleName,
            next: role.roleName,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.purpose || role.purpose) {
      if (data.purpose !== role.purpose) {
        if (!data.purpose && role.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: role.purpose,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && role.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: role.purpose,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && !role.purpose) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: role.purpose,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (arr.tasks || role.tasks) {
      if (arr.tasks.join() !== role.tasks.join()) {
        if (arr.tasks.join() === "" && role.tasks.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: arr.tasks.join(),
            next: role.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          arr.tasks.join().length > 0 &&
          role.tasks.join().length > 0
        ) {
          let obj = {
            cardId: arr._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: role.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (arr.tasks.join().length > 0 && role.tasks.join() === "") {
          let obj = {
            cardId: arr._id,
            teamId: teamId,
            field: "Tasks",
            prev: arr.tasks.join(),
            next: role.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.ownerRole || role.ownerRole) {
      if (data.ownerRole !== role.ownerRole) {
        if (
          data.ownerRole &&
          data.ownerRole.length > 0 &&
          role.ownerRole &&
          role.ownerRole.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner Role",
            prev: data.ownerRole,
            next: role.ownerRole,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.owners || role.owners) {
      let count = 0;
      let flag = false;
      if (
        data.owner &&
        data.owners.length > 0 &&
        role.owners &&
        role.owners.length > 0
      ) {
        for (let i = 0; i < data.owners.length; i++) {
          for (let j = 0; j < role.owners.length; j++) {
            if (data.owners[i] === role.owners[j]) {
              count++;
            }
          }
        }
      }
      if (
        (data.owners &&
          role.owners &&
          data.owners.length === role.owners.length) ||
        (data.owners && count === data.owners.length)
      ) {
        flag = true;
      }
      let oldOwners = "";
      let newOwners = "";
      if (peoples && peoples.length > 0 && flag) {
        for (let i = 0; i < peoples.length; i++) {
          for (let j = 0; j < data.owners.length; j++) {
            if (peoples[i]._id === data.owners[j]) {
              if (j === 0) {
                oldOwners = `${peoples[i].fname} ${peoples[i].lname}`;
              } else if (j > 0) {
                oldOwners =
                  oldOwners + ", " + `${peoples[i].fname} ${peoples[i].lname}`;
              }
            }
          }
          for (let j = 0; j < role.owners.length; j++) {
            if (peoples[i]._id === role.owners[j]) {
              if (j === 0) {
                newOwners = `${peoples[i].fname} ${peoples[i].lname}`;
              } else if (j > 0) {
                newOwners =
                  newOwners + ", " + `${peoples[i].fname} ${peoples[i].lname}`;
              }
            }
          }
        }
      }
      if (oldOwners !== newOwners && flag) {
        if (oldOwners === "" && newOwners.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldOwners.length > 0 && newOwners.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldOwners.length > 0 && newOwners === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.tags && data.tags.length > 0) ||
      (role.tags && role.tags.length > 0)
    ) {
      if (data.tags && role.tags && data.tags.join() !== role.tags.join()) {
        if (data.tags.join() === "" && role.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: role.tags.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && role.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: role.tags.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && role.tags.length === 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: role.tags.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
  }

  const RConToSkill = (data) => {
    if (data._id && data._id.length > 0) {
      let objData = {
        teamId: data.teamId,
        skillName: data.roleName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerType: data.ownerRole,
        owners: data.owners,
        roleId: data._id,
        domains: data.domains,
        tags: data.tags,
        url: `${SERVER_URI}/user/skill/create`,
        adminId: adminId,
      };

      let objsData = {};
      objsData.teamId = localStorage.getItem("teamId");
      objsData.userId = myInfo.userId;

      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.adminId = role.adminId;
      obj.url = `${SERVER_URI}/user/role/delete`;
      obj.roleId = data._id;
      let objs = {};
      objs.teamId = localStorage.getItem("teamId");
      objs.userId = myInfo.userId;
      /* let cuRoles = states.Roles.filter((e) => {
        return e !== obj.roleId;
      }); */

      freezeWindow(false);
      dispatch(createSkills({ ...objData, transfer: true })).then(() => {
        // dispatch(getSkills(objsData));
        let roleSort = localStorage.getItem("roleSort");
        dispatch(deleteRole(objData)).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: objData.teamId }));
          dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          unfreezeWindow();
        });
      });
    }
  };

  const handelUpdate = async () => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (role._id) {
      let roleSort = localStorage.getItem("roleSort");
      dispatch(updateRole({ ...role, roleSort })).then(() => {
        let curDomain = localStorage.getItem("domainSort");
        dispatch(getDomain({ ...obj, curdomain: curDomain }));
        dispatch(historyCreate(historyState));
      });
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
    return true;
  };

  // next and previous for each role in current modal
  const handlePreviosNext = async (data) => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    let curUpdateData = [];
    if (roles && roles.length > 0 && isNToP && isNToP.length > 0) {
      for (let i = 0; i < isNToP.length; i++) {
        for (let j = 0; j < roles.length; j++) {
          if (roles[j]._id === isNToP[i]._id) {
            curUpdateData.push(roles[j]);
            break;
          }
        }
      }
    }

    if (data === "prev") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (curUpdateData[i]._id === role._id && i >= 0) {
            localStorage.setItem("npState", "Role");
            let index = i === 0 ? curUpdateData.length - 1 : i - 1;
            await handelUpdate().then(() => {
              setRoles(curUpdateData[index], curUpdateData, 0, "Role");
            });
          }
        }
      }
    } else if (data === "next") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (
            curUpdateData[i]._id === role._id &&
            i <= curUpdateData.length - 1
          ) {
            localStorage.setItem("npState", "Role");
            let index = i < curUpdateData.length - 1 ? i + 1 : 0;
            await handelUpdate().then(() => {
              setRoles(curUpdateData[index], curUpdateData, 0, "Role");
            });
          }
        }
      }
    }
  };

  // copy the current role
  const roleCopy = (e) => {
    e.preventDefault();
    dispatch({ type: HISTORY_DATAS_CLEAR });
    setRole({
      ...role,
      _id: "",
      roleName: role.roleName + " (Copy)",
    });
    // dispatch(createRole(datas))
  };

  // create a new role and also update the role0

  const roleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    localStorage.setItem("npState", "");
    let roleSort = localStorage.getItem("roleSort");
    let domainSort = localStorage.getItem("domainSort");
    let circleSort = localStorage.getItem("circleSort");
    let projectSort = localStorage.getItem("projectSort");
    role.documents = documentArray;
    if (role._id) {
      dispatch(updateRole({ ...role, roleSort, /* owners: data.owners */ })).then(
        () => {
          dispatch(historyCreate(historyState));
          dispatch(getDomain({ ...obj, curdomain: domainSort }));
          dispatch(getCircle({ ...obj, curcircle: circleSort }));
          dispatch(getProject({ ...obj, curproject: projectSort }));
        }
      );
      // console.log("😁😁😁")
    } else {
      dispatch(
        createRole({
          ...role,
          teamId: teamId,
          roleSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        dispatch(getDomain({ ...obj, curdomain: domainSort }));
      });
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
  };

  useEffect(() => {
    if (CloseState && CloseState === "Roles") {
      stateCloseState("");
      //update
      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.userId = myInfo.userId;
      localStorage.setItem("npState", "");
      let roleSort = localStorage.getItem("roleSort");
      let domainSort = localStorage.getItem("domainSort");
      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      if (role._id) {
        role.documents = documentArray;
        dispatch(
          updateRole({ ...role, roleSort, domainSort, circleSort, projectSort })
        ).then(() => {
          // dispatch(getDomain({ ...obj, domainSort: domainSort }));
          dispatch(historyCreate(historyState));
        });
      } else {
        role.documents = documentArray;
        dispatch(
          createRole({
            ...role,
            teamId: teamId,
            roleSort,
            domainSort,
            circleSort,
            projectSort,
          })
        ).then(() => {
          // dispatch(getDomain({ ...obj, domainSort: domainSort }));
        });
      }
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  }, [CloseState, stateCloseState]);

  // delete current role

  const roleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You can still restore this from trash!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.adminId = role.adminId;
        obj.url = `${SERVER_URI}/user/role/delete`;
        obj.roleId = role._id;
        obj.softdelete = true;
        let objs = {};
        objs.teamId = localStorage.getItem("teamId");
        objs.userId = myInfo.userId;
        /* let cuRoles = states.Roles.filter((e) => {
          return e !== obj.roleId;
        }); */
        // console.log(objs)
        let roleSort = localStorage.getItem("roleSort");
        dispatch(deleteRole(obj)).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: ROLE_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          /* localStorage.setItem("roleSort", cuRoles);
          dispatch(
            updateState({
              adminId: myInfo.userId,
              teamId: obj.teamId,
              url: `${SERVER_URI}/user/states/update`,
              Roles: cuRoles,
            })
          ).then(() => {
            dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
            dispatch({ type: ROLE_ID_CLEAR });
            dispatch({ type: HISTORY_DATAS_CLEAR });
            // dispatch(getRole({ ...objs, currole: cuRoles }));
          }); */
        });
        // dispatch({ ROLE_SUCCESS_MESSAGE_CLEAR })

        // closeRole()
      }
    });
  };

  useEffect(() => {
    if (roleMessagee) {
      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.userId = myInfo.userId;

      if (roleMessagee) {
        let curdomain = localStorage.getItem("domainSort");
        let curdomains = curdomain.split(",");
        // console.log(nDaoamins);
        if (role.domains.length > 0 && nDaoamins && nDaoamins.length == 0) {
          for (let i = 0; i < role.domains.length; i++) {
            if (
              role.domains[i].owner !== null &&
              role.domains[i].domain !== null
            ) {
              // let targetDomain = domains.find((d)=> d._id === role.domains[i].domain);
              // console.log(targetDomain);
              // if(targetDomain){
              //   dispatch(
              //     updateDomain({
              //       adminId: myInfo.userId,
              //       teamId: obj.teamId,
              //       url: `${SERVER_URI}/user/domain/update`,
              //       _id: role.domains[i].domain,
              //       owners: {
              //         type: role._id && role._id.length > 0 ? role._id : cuRoleId,
              //         owner:
              //           role.domains[i] &&
              //             role.domains[i].owner &&
              //             role.domains[i].owner.length > 0
              //             ? role.domains[i].owner
              //             : null,
              //       },
              //       domainName: targetDomain.domainName
              //     })
              //   ).then(() => {
              //     dispatch(getDomain({ ...obj, curdomain: curdomains }));
              //     dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
              //   });
              // }
            }
          }
        } else if (
          role.domains.length > 0 &&
          nDaoamins &&
          nDaoamins.length > 0
        ) {
          for (let i = 0; i < role.domains.length; i++) {
            if (
              nDaoamins[i] &&
              role.domains[i].owner !== null &&
              role.domains[i].domain !== null
            ) {
              // console.log(nDaoamins[i]);
              let targetDomain = domains.find((d) => d._id === role.domains[i].domain);
              console.log(targetDomain);
              if (targetDomain) {
                dispatch(
                  updateDomain({
                    adminId: myInfo.userId,
                    teamId: obj.teamId,
                    url: `${SERVER_URI}/user/domain/update`,
                    _id: nDaoamins[i],
                    owners: {
                      type: role._id && role._id.length > 0 ? role._id : cuRoleId,
                      owner:
                        role.domains[i] &&
                          role.domains[i].owner &&
                          role.domains[i].owner.length > 0
                          ? role.domains[i].owner
                          : null,
                    },
                    domainName: targetDomain.domainName
                  })
                ).then(() => {
                  // dispatch(getDomain({ ...obj, curdomain: curdomains }));
                  dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
                });
              }
            }
          }
        }

        let currole = localStorage.getItem("roleSort");
        let curroles = currole.split(",");
        /* if (curTag && curTag.length > 0) {
          dispatch(
            tagCreate({
              adminId: myInfo.userId,
              teamId: obj.teamId,
              url: `${SERVER_URI}/user/tags/create`,
              tags: curTag,
            })
          ).then(() => {
            dispatch({ type: TAGS_SUCCESS_MESSAGE_CLEAR });
            dispatch(getTags(obj));
          });
        } */
        // dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
        // console.log('first')
        // dispatch(getRole({ ...obj, currole: curroles }));
        // console.log('second')
        let cueState = localStorage.getItem("npState");
        if (cueState !== "Role") {
          closeRole();
        }
      }

      dispatch({ type: ROLE_ID_CLEAR });
      // dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
    }
    if (roleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: roleError,
      });
      // alert.error(peopleError)
      dispatch({ type: ROLE_ERROR_CLEAR });
      // dispatch(getRole(obj));
      // closeRole();
    }
    dispatch(getTags({ userId: myInfo.userId }));
  }, [roleMessagee, roleError, cuRoleId, cuSkillId]);

  // get current role history

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  useEffect(() => {
    if (historyMessage) {
      dispatch({ type: HISTORY_MESSAGE_CLEAR });
    }
  }, [historyMessage && historys]);

  // set history for any changes i current role modal any field accordingly

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  const ref = useRef(null);

  const handleClick = () => {
    ref.current.focus();
  };

  const CloseRoleModal = () => {
    setPurposetreeLoader((prev=>({...prev,role: false})));
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeRole();
  };

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const items = [...formValues];
    const draggedItem = items[draggedIndex];
    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);
    setFormValues(items);
  };

  const CustomOption = ({ innerProps, label, value }) => {
    let flag = false;
    if (peoples && peoples.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === value && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
    }
    let data = ``;
    if (flag) {
      data += `<span class="custom-badge blue">EXT</span>`;
    }
    data += label;
    return (
      <div {...innerProps} className="tdListItem">
        <span dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    );
  };

  const isExtPerson = (Id) => {
    if (peoples && peoples.length > 0) {
      let flag = false;
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === Id && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
      return flag;
    }
  };

  const outerBoxClick = () => {
    if (
      formValues &&
      formValues.length > 0 &&
      formValues[formValues.length - 1].tasks &&
      formValues[formValues.length - 1].tasks.length > 0
    ) {
      addFormFields();
      setIsCliking(true);
    }
  };

  const [workloadState, setWorkLoadState] = useState(role.workload || []);

  const handleWorkloadChange = (sv, inputVal) => {
    if (inputVal > 1) {
      inputVal = 1;
    } else if (inputVal < 0) {
      inputVal = 0;
    }
    // Find the index of the item in workloadState that matches the ownerId (sv.value)
    const existingIndex = workloadState.findIndex(
      (item) => item && item.ownerId === sv.value
    );

    // Create a new copy of the workloadState array
    const updatedWorkloadState = [...workloadState];

    if (existingIndex !== -1) {
      // If the item already exists, update its plan
      updatedWorkloadState[existingIndex] = {
        ...updatedWorkloadState[existingIndex],
        plan: inputVal,
      };
    } else {
      // If the item does not exist, add a new item
      updatedWorkloadState.push({
        ownerId: sv.value,
        plan: inputVal,
      });
    }

    setRole({ ...role, workload: updatedWorkloadState });
    // Update the state with the new workloadState array
    setWorkLoadState(updatedWorkloadState);
  };

  const HandleSecretDownloadTool = () => {

    // Create the content of the file
    const fileContent =
      `Name: ${role.roleName}\nPurpose: ${role.purpose}\nTasks: ${role.tasks.join(',')}\nOwners: ${value.map(sv => sv.label).filter(Boolean).join(',')}\nTags: ${role.tags.join(',')}`;

    // Create a Blob object from the content with file content and file type
    const blob = new Blob([fileContent], { type: 'text/plain' });

    // Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;

    // Set the download attribute with a default file name
    link.download = `${role.roleName}_role.txt`;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the object URL
    URL.revokeObjectURL(fileUrl);
  };

  return (
    <div
      id="roleModal"
      onClick={() => outerBoxClick()}
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <form className="form" onSubmit={(e) => roleSubmit(e)}>
          {/* Modal content*/}
          <div className="modal-content">
            <div className="modal-header header-blue">
              <div className="row ">
                <div className="col-xs-8 text-left">
                  <h4 className="modal-title">
                    <img
                      src="images/icon-role.png"
                      style={{
                        width: "17px",
                        marginTop: "-3px",
                        marginRight: "5px",
                      }}
                      onClick={HandleSecretDownloadTool}
                    />{" "}
                    {/* ROLE */GetAliasesName(teams, "Roles").toUpperCase()}{" "}
                    {role._id && !role.defaultRole ? (
                      <span
                        className="move-to"
                        onClick={() => RConToSkill(role)}
                      >
                        Convert To {/* Skill */} {GetAliasesName(teams, "Skills")}
                      </span>
                    ) : null}
                  </h4>
                </div>
                {compareMode.active ||
                  (focusMode && focusMode.length > 0) ? null : (
                  <div className="col-xs-4 text-right">
                    <Tippy content={<span>Previous Role</span>}>
                      <button
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        type="button"
                        data-tippy-content="Previous Role"
                        className="btn btn-mytpt-gray show-prev"
                        onClick={() => handlePreviosNext("prev")}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </Tippy>
                    <Tippy content={<span>Next Role</span>}>
                      <button
                        style={{ cursor: "pointer" }}
                        type="button"
                        data-tippy-content="Next Role"
                        className="btn btn-mytpt-gray show-next"
                        onClick={() => handlePreviosNext("next")}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </Tippy>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextareaAutosize
                      type="text"
                      ref={ref}
                      readOnly={role.defaultRole}
                      name="roleName"
                      value={role.defaultRole /* && ["Circle", "Project", "Link", "Domain", "Role", "Skill"].includes(GetAliasesName(teams, role.roleName.split(" ")[0])) */ ? `${GetAliasesName(teams, role.roleName.split(" ")[0]) || ""} ${role.roleName.split(" ")[1] || ""} ` : role.roleName}
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      className="form-control tool-name logit"
                      placeholder={`+ Add ${GetAliasesName(teams, "Roles").toLowerCase()} name`}
                      style={centeredPlaceholderStyle}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    <TextareaAutosize
                      // cacheMeasurements
                      type="text"
                      value={role.purpose}
                      onChange={handleChange}
                      className="form-control purpose logit"
                      name="purpose"
                      placeholder="+ Add purpose"
                    />
                  </div>

                  <div className="form-group tasks">
                    <Tasks
                      formValues={formValues}
                      onDragStart={onDragStart}
                      onDragOver={onDragOver}
                      onDrop={onDrop}
                      handleForm={handleForm}
                      handleKeyPress={handleKeyPress}
                      removeFormFields={removeFormFields}
                    />
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={role} role={role} />
                  </div>

                  <WorkLoadSRDL
                    handleChange={handleChange}
                    planTotal={parseFloat(role.planTotal)}
                    status={sumFTE(role.workload, value)}
                  />

                  <div className="form-group">
                    <h4>Notes</h4>
                    <TextareaAutosize
                      className="form-control purpose logit"
                      name="notes"
                      defaultValue=''
                      value={role.notes}
                      onChange={handleChange}
                      placeholder="+ Add notes"
                      maxLength="100"
                      autoFocus={null}
                    />
                  </div>

                </div>

                <div className="col-md-6">
                  {!role.defaultRole ? (
                    <>
                      <div className="form-group role-type">
                        <input
                          type="radio"
                          className="logit"
                          id="single_owner_role"
                          name="ownerRole"
                          value="Single owner"
                          checked={
                            role.ownerRole === "Single owner" ? true : false
                          }
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="single_owner_role"
                          className="inline-label"
                        >
                          Single owner
                        </label>

                        <input
                          type="radio"
                          className="logit"
                          id="multiple_owner_role"
                          name="ownerRole"
                          value="Multiple owners"
                          checked={
                            role.ownerRole === "Multiple owners" ? true : false
                          }
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="multiple_owner_role"
                          className="inline-label"
                        >
                          Multiple owners
                        </label>

                        {role.ownerRole === "Single owner" ? (
                          <div
                            className="form-group flag"
                            style={{ display: "block" }}
                          >
                            <label>
                              Single owner role can be Circle/ Project lead
                            </label>
                          </div>
                        ) : null}
                      </div>

                      {role.ownerRole === "Single owner" ? (
                        <div className="form-group role-owners ss">
                          <div className="row">
                            <div
                              className="col-xs-9"
                              style={{ paddingRight: 0 }}
                            >
                              <h4>Owner</h4>
                            </div>
                            <div className="col-xs-3">
                              <h4>FTE</h4>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-xs-9"
                              style={{ paddingRight: 0 }}
                            >
                              <Select
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="single-select-container"
                                // components={animatedComponents}
                                isClearable
                                isSearchable
                                onChange={(newValue) => handelSelect(newValue)}
                                defaultValue={
                                  value && value.length > 0
                                    ? value.map((cumentor, cmindex) => {
                                      return {
                                        label: (
                                          <div style={{ fontSize: "14px" }}>
                                            {cumentor.value &&
                                              isExtPerson(cumentor.value) ? (
                                              <>
                                                <span
                                                  className="custom-badge blue"
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  EXT
                                                </span>{" "}
                                                {cumentor.label}
                                              </>
                                            ) : (
                                              cumentor.label
                                            )}
                                          </div>
                                        ),
                                        value: cumentor.value,
                                      };
                                    })
                                    : null
                                }
                                options={options}
                                placeholder="+ Add owner"
                                components={{ Option: CustomOption }}
                              />
                            </div>
                            <div className="col-xs-3">
                              {value && value.length > 0 ? (
                                value.map((sv, index) => {
                                  if (index > 0) return null;

                                  // Find the corresponding plan value from workloadState based on ownerId (sv.value)
                                  const matchingWorkload = workloadState.find(
                                    (item) => item && item.ownerId === sv.value
                                  );

                                  // Extract the plan value or use an empty string if it doesn't exist
                                  const planValue = matchingWorkload
                                    ? matchingWorkload.plan
                                    : "";

                                  return (
                                    <input
                                      type="number"
                                      id={`plan-${index}`}
                                      className="form-control fte"
                                      placeholder="Max: 1"
                                      name={`plan-${index}`}
                                      value={planValue} // Bind the input value to planValue
                                      step="any"
                                      onChange={(e) =>
                                        handleWorkloadChange(sv, e.target.value)
                                      }
                                      onWheel={numberInputOnWheelPreventChange}
                                      key={index}
                                    />
                                  );
                                })
                              ) : (
                                <input
                                  type="number"
                                  id="plan-0"
                                  className="form-control fte"
                                  placeholder="Max: 1"
                                  name="plan-0"
                                  defaultValue=""
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="form-group role-owners ms">
                          <div className="row">
                            <div
                              className="col-xs-9"
                              style={{ paddingRight: 0 }}
                            >
                              <h4>Owners</h4>
                            </div>
                            <div className="col-xs-3">
                              <h4>FTE</h4>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-xs-9"
                              style={{ paddingRight: 0 }}
                            >
                              <Select
                                closeMenuOnSelect={false}
                                classNamePrefix="select"
                                className="multiple-select-container"
                                // components={animatedComponents}
                                isClearable
                                isSearchable
                                onChange={(newValue) => handelSelect(newValue)}
                                defaultValue={
                                  value && value.length > 0
                                    ? value.map((cumentor, cmindex) => {
                                      return {
                                        label: (
                                          <div style={{ fontSize: "14px" }}>
                                            {/* {cumentor.value &&
                                            isExtPerson(cumentor.value) ? (
                                              <span className="custom-badge blue">
                                                EXT
                                              </span>
                                            ) : null}
                                            {cumentor.label} */}

                                            {cumentor.value &&
                                              isExtPerson(cumentor.value) ? (
                                              <>
                                                <span
                                                  className="custom-badge blue"
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  EXT
                                                </span>{" "}
                                                {cumentor.label}
                                              </>
                                            ) : (
                                              cumentor.label
                                            )}
                                          </div>
                                        ),
                                        value: cumentor.value,
                                      };
                                    })
                                    : null
                                }
                                isMulti
                                options={options}
                                components={{ Option: CustomOption }}
                              // menuIsOpen={true}
                              />
                            </div>
                            <div class="col-xs-3">
                              {(value ? [...value, {}] : [{}]).map(
                                (sv, index) => {
                                  // Find the corresponding plan value from workloadState based on ownerId (sv.value)
                                  const matchingWorkload = workloadState.find(
                                    (item) => item && item.ownerId === sv.value
                                  );

                                  // Extract the plan value or use an empty string if it doesn't exist
                                  const planValue = matchingWorkload
                                    ? matchingWorkload.plan
                                    : "";

                                  return (
                                    <input
                                      key={index}
                                      // style={{ margin: "2px 2px 5px 0" }}
                                      type="number"
                                      id={`plan-${index}`}
                                      className="form-control fte"
                                      name={`plan-${index}`}
                                      placeholder="Max: 1"
                                      value={
                                        index < value.length ? planValue : ""
                                      } // Bind the input value to planValue or empty for the new input
                                      step="any"
                                      disabled={
                                        index === value.length ? true : false
                                      } // Make the additional field read-only initially
                                      onFocus={(e) =>
                                        index === value.length &&
                                        e.target.removeAttribute("readOnly")
                                      }
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        if (
                                          newValue &&
                                          index === value.length
                                        ) {
                                          // Update the value state to include the new entry
                                          const newValues = [
                                            ...value,
                                            { value: newValue },
                                          ];
                                          setValue(newValues);
                                          handleWorkloadChange(
                                            { value: newValue },
                                            newValue
                                          );
                                        } else {
                                          handleWorkloadChange(sv, newValue);
                                        }
                                      }}
                                      onWheel={numberInputOnWheelPreventChange}
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {value && value.length > 0 ? null : (
                        <div
                          className="form-group flag"
                          style={{ display: "block" }}
                        >
                          <label>
                            Roles without owners will be flagged:{" "}
                            <span className="custom-badge red">NO</span>
                          </label>
                        </div>
                      )}

                      <div className="form-group domains">
                        <h4>{/* Domains */GetAliasesName(teams, "Domains")}</h4>
                        <div className="row">
                          <div className="col-xs-5">
                            <div className="flag">
                              <label>This {/* Role */GetAliasesName(teams, "Roles").toLowerCase()} owns these {/* domains */}{GetAliasesName(teams, "Domains").toLowerCase()}:</label>
                            </div>
                          </div>
                          <div
                            className="col-xs-5"
                          // style={{ paddingLeft: "5px" }}
                          >
                            <div className="flag">
                              <label>Responsible:</label>
                            </div>
                          </div>
                          <div className="col-xs-2"></div>
                        </div>

                        <div className="domain-list domain-container">
                          {domainsValue.map((element, index) => {
                            // console.log(element)
                            let curDomain = [];
                            let curOwner = [];
                            if (
                              element.domain &&
                              element.domain.value &&
                              element.domain.value.length > 0
                            ) {
                              curDomain.push(element.domain);
                            }
                            if (
                              element.owner &&
                              element.owner.value &&
                              element.owner.value.length > 0
                            ) {
                              curOwner.push(element.owner);
                            }
                            // console.log(domainsValue[index].domain)
                            return (
                              <div className="domain" key={index}>
                                <div
                                  className="choose-domain ss"
                                  style={{ paddingRight: "5px" }}
                                >
                                  <CreatableSelect
                                    ref={(ref) =>
                                      (selectInputRefs.current[index] = ref)
                                    }
                                    components={animatedComponents}
                                    isClearable
                                    isSearchable={
                                      domainsValue[index].domain &&
                                        domainsValue[index].domain.value
                                        ? false
                                        : true
                                    }
                                    classNamePrefix="select"
                                    onChange={(e) =>
                                      handleDomainsForm(index, e)
                                    }
                                    // onMenuOpen={() => handleDomainsFormClear(index)}
                                    value={curDomain}
                                    options={opDoamin}
                                    placeholder={`+ Add ${GetAliasesName(teams,"Domains").toLowerCase()}`}
                                    openMenuOnClick={
                                      domainsValue[index].domain &&
                                        domainsValue[index].domain.value
                                        ? false
                                        : true
                                    }
                                  />
                                </div>
                                <div
                                  className="pick-owner ss"
                                  style={{ paddingRight: "0px" }}
                                >
                                  <Select
                                    // closeMenuOnSelect={false}
                                    isClearable
                                    isSearchable
                                    // components={animatedComponents}
                                    classNamePrefix="select"
                                    onChange={(e) => handleOwnerForm(index, e)}
                                    // value={curOwner}
                                    value={
                                      curOwner &&
                                        curOwner.length > 0 &&
                                        curOwner[0].value &&
                                        curOwner[0].value.length > 0
                                        ? [
                                          {
                                            value: curOwner[0].value,
                                            label: (
                                              <div
                                                style={{ fontSize: "14px" }}
                                              >
                                                {/* {curOwner[0].value && isExtPerson(curOwner[0].value) ?
                                          <span className="custom-badge blue"  >
                                            EXT
                                          </span>
                                          : null} */}
                                                {curOwner[0].label}
                                              </div>
                                            ),
                                          },
                                        ]
                                        : []
                                    }
                                    options={domainOwners}
                                    placeholder="+ Pick owner"
                                    components={{ Option: CustomOption }}
                                  />
                                </div>
                                <div
                                  className="domain-actions"
                                  style={{ paddingLeft: "3px" }}
                                >
                                  {index !== domainsValue.length ? (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        removeFormDomainsFields(index)
                                      }
                                      className="button btn-mytpt-gray remove-attribution btn btn-remove"
                                    >
                                      <span
                                        style={{ paddingLeft: "1px" }}
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></span>
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}

                          <button
                            type="button"
                            onClick={() => addFormDomainsFields()}
                            className="btn btn-mytpt attribute-domain pull-right"
                            data-modal_id="rolesModal"
                            style={{ width: "maxContent" }}
                          >
                            <span className="fa fa-plus"> </span>
                          </button>
                        </div>
                      </div>

                      <div className="form-group member-in">
                        <h4>Member In</h4>
                        <div className="form-group flag">
                          <label>This {/* role */GetAliasesName(teams, "Roles").toLowerCase()} is currently member in:</label>
                        </div>
                        <div
                          className="row"
                          style={{ marginRight: 0, marginLeft: 0 }}
                        >
                          {memberIds && memberIds.length > 0
                            ? memberIds.map((e, index) => {
                              if (
                                !e.memberName ||
                                e.memberName === "" ||
                                e.memberName === null
                              )
                                return;

                              return (
                                <div key={index} className="col-xs-12">
                                  {e.memberName}
                                  {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                    <span
                                      style={{
                                        display: "inlineBlock",
                                        marginBottom: "0",
                                      }}
                                      className="custom-badge lightgray person-pill text-red"
                                    >
                                      <span className="text-red">XTD</span>
                                    </span>
                                  ) : null}
                                  <a
                                    className="remove"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "60%",
                                    }}
                                  >
                                    <span
                                      onClick={() => memberHandel(e)}
                                      className="fa fa-times"
                                    ></span>
                                  </a>
                                </div>
                              );
                            })
                            : null}
                        </div>
                      </div>

                      <Documents
                        data={data}
                        setDocumentData={setDocumentData}
                      />

                      <div className="form-group tags">
                        <h4>Tags</h4>
                        <Tags
                          data={arr}
                          onChange={handleChange}
                          role={role}
                          name="tags"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <DefaultRoleOwners
                        value={value}
                        setValue={setValue}
                        workloadState={workloadState}
                        isExtPerson={isExtPerson}
                        handleWorkloadChange={handleWorkloadChange}
                        numberInputOnWheelPreventChange={
                          numberInputOnWheelPreventChange
                        }
                      />

                      <Documents
                        data={data}
                        setDocumentData={setDocumentData}
                      />

                      <div
                        id="mentor-note"
                        className="form-group default-role-note"
                      >
                        <h4 style={{ margin: 0 }}>This is a GENERIC ROLE!</h4>
                        <a
                          className="more-info"
                          href= "https://teamdecoder.tawk.help/article/generic-items"
                          target="_blank"
                          rel="noopener nofollow"
                        >
                          More info
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  {localStorage.getItem("mode") !== "View" && role._id && !role.defaultRole ? (
                    <Tippy content={<span>Copy This {/* Role */ GetAliasesName(teams, "Roles")}</span>}>
                      <button
                        type="button"
                        onClick={(e) => roleCopy(e)}
                        className="btn btn-mytpt-gray clone"
                        data-id="rolesModal"
                      >
                        <img alt="" src="./images/newicons/copy.svg" />
                      </button>
                    </Tippy>
                  ) : null}

                  {localStorage.getItem("mode") !== "View" && role._id && !role.defaultRole ? (
                    <Tippy content={<span>Delete This {/* Role */GetAliasesName(teams, "Roles")}</span>}>
                      <button
                        type="button"
                        onClick={roleDelete}
                        className="btn btn-mytpt-gray delete"
                        data-id="rolesModal"
                        data-post_type="role"
                      >
                        <img alt="" src="./images/newicons/delete.svg" />
                      </button>
                    </Tippy>
                  ) : null}
                  <Tippy content={<span>View History</span>}>
                    <button
                      type="button"
                      onClick={() => historysGet(role._id)}
                      style={
                        historysMessage.length > 0
                          ? { backgroundColor: "#F3D068" }
                          : null
                      }
                      className="btn btn-mytpt-gray history"
                    >
                      <img alt="" src="./images/newicons/history.svg" />
                    </button>
                  </Tippy>
                </div>
                <div className="col-xs-8 text-right">
                  <Tippy content={<span>Close Tool</span>}>
                    <button
                      type="button"
                      onClick={CloseRoleModal}
                      className="btn btn-mytpt-red"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </Tippy>
                  {localStorage.getItem("mode") !== "View" && (
                    <Tippy content={<span>Save And Close</span>}>
                      <button
                        type="submit"
                        className="btn btn-mytpt save-close"
                        data-modal_id="roleModal"
                      >
                        <i className="fa fa-check"></i>
                      </button>
                    </Tippy>
                  )}
                </div>
              </div>

              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
