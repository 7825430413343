import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../../store/types/historyType";
import { historyGet } from "../../../../store/actions/historyAction";
import WorkLoadSRDL from "../../Body/Model/WorkLoadSRDL";
import { sumFTEBody } from "../../../../HelperFunctions/FTE_cal";
import Objectives from "../../Body/Model/Objectives";
import { numberInputOnWheelPreventChange } from "../../../../HelperFunctions/StopInputScroll";
import Documents from "../../Body/Model/Documents";
import DefaultRoleOwners from "../../Body/Model/DefaultRoleOwners";
import { GetAliasesName } from "../../../../HelperFunctions/GetAliasesName";

export default function RoleModal({ data, closeRole, teams }) {
  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting
  } = useSelector((state) => state.authReport);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { domains } = useSelector((state) => state.domains);
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { peoples, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );

  const { roles, cuRoleId, nDaoamins, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );

  const dispatch = useDispatch();
  let curOwners = [];
  if (
    data &&
    data.owners &&
    data.owners.length > 0 &&
    reportPeople.length > 0
  ) {
    for (let i = 0; i < reportPeople.length; i++) {
      let inc = data.owners.includes(reportPeople[i]._id);
      if (inc) {
        curOwners.push(reportPeople[i]);
      }
    }
  }

  var cumentor = [];

  // for (let i = 0; i < reportPeople.length; i++)

  // useEffect(() =>{
  if (curOwners && curOwners.length > 0) {
    for (let i = 0; i < curOwners.length; i++) {
      const { workload } = data;

      for (let j = 0; j < workload.length; j++) {
        if (
          workload &&
          workload[j].ownerId.toString() === curOwners[i]._id.toString()
        ) {
          const ownerObj = {
            name: `${curOwners[i].fname} ${curOwners[i].lname}`,
            plan: workload[j].plan,
          };
          cumentor.push(ownerObj);
        }
      }
    }
  }
  // console.log(cumentor);
  // },[curOwners])

  // if (data && data.owners) {
  //   if (data && data.owners.length > 0) {
  //     for (let i = 0; i < peoples.length; i++) {
  //       let inc = data.owners.includes(peoples[i]._id);
  //       // if (inc) {
  //       //   cumentor.push({
  //       //     value: `${peoples[i]._id}`,
  //       //     label: `${peoples[i].fname} ${peoples[i].lname}`,
  //       //     fte: plan
  //       //   });
  //       // }
  //     }
  //   }
  // }

  // console.log(cumentor);

  // console.log(roles);

  // useEffect(() => {
  //   if (roles && roles.length > 0) {
  //     for (let i = 0; i < roles.length; i++) {
  //       const { workload } = roles[i];
  //       // console.log(workload);

  //       if(workload && workload.length > 0){
  //         for(let j = 0; j < workload.length; j++){
  //           console.log(workload[j].ownerId);
  //           // const isPresent = cumentor.some(data => data.value.toString() === workload[j].ownerId.toString())
  //           const isPresent = cumentor.some(data => {console.log(data) , workload[j]})
  //           // console.log(isPresent)
  //         }
  //       }
  //     }
  //   }
  // }, [roles]);

  let cuDomains = [];

  if (
    data &&
    data.domains &&
    data.domains.length > 0 &&
    reportPeople.length > 0 &&
    reportDomain.length > 0
  ) {
    for (let i = 0; i < data.domains.length; i++) {
      let cuDomain = {};
      for (let j = 0; j < reportDomain.length; j++) {
        if (data.domains[i].domain === reportDomain[j]._id) {
          cuDomain.domainId = reportDomain[j]._id;
          cuDomain.domainName = reportDomain[j].domainName;
        }
      }
      for (let k = 0; k < reportPeople.length; k++) {
        if (data.domains[i].owner === reportPeople[k]._id) {
          cuDomain.ownerId = reportPeople[k]._id;
          cuDomain.ownerName = `${reportPeople[k].fname} ${reportPeople[k].lname}`;
        }
      }
      if (cuDomain) {
        cuDomains.push(cuDomain);
      }
    }
  }

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  let memberIds = [];
  if (data) {
    members.map((e, index) => {
      let inc = e.coreMembers.Roles.includes(data._id);
      let incs = e.extendedMembers.Roles.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, XTD: "" });
      }
      if (incs) {
        memberIds.push({ memberId: e.memberType, XTD: "XTD" });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + "(circle)";
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + "(project)";
        }
      }
    }
  }

  const CloseRoleModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeRole();
  };
  // console.log(data);
  return (
    <div
      id="roleModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable report-modal">
        <form className="form">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header header-blue">
              <h4 className="modal-title">
                <img
                  src="../images/icon-role.png"
                  style={{
                    width: "17px",
                    marginTop: "-3px",
                    marginRight: "5px",
                  }}
                />
                {/* ROLE */GetAliasesName(teams, "Roles").toUpperCase()}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div
                        name="role_name"
                        className="form-control tool-name"
                        placeholder="Role name"
                      >
                        {data && data.roleName ? data.roleName : ""}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    {data && data.purpose ? (
                      <TextareaAutosize
                        // cacheMeasurements
                        type="text"
                        value={data.purpose}
                        className="form-control purpose logit"
                        name="role_purpose"
                        placeholder="+ Add purpose"
                        disabled
                        readOnly
                      />
                    ) : null}
                  </div>
                  <div className="form-group" data-id="task-list">
                    <h4>Tasks</h4>
                    {data.tasks && data.tasks.length > 0
                      ? data.tasks &&
                      data.tasks.length > 0 && (
                        <div className="row task-list">
                          {data.tasks && data.tasks.length > 0
                            ? data.tasks.map((r, tindex) => {
                              return (
                                <div
                                  className="col-xs-12 task"
                                  key={tindex}
                                  style={{ position: "relative" }}
                                >
                                  <TextareaAutosize
                                    // cacheMeasurements
                                    id={`task${tindex}`}
                                    type="text"
                                    placeholder="+ Add task"
                                    name="tasks"
                                    className="form-control logit"
                                    value={r}
                                    disabled
                                  >
                                    <span className="fa fa-circle"> </span>
                                  </TextareaAutosize>
                                </div>
                              );
                            })
                            : null}
                        </div>
                      )
                      : null}
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={data} skill={data} type="REPORT_DATA" />
                  </div>

                  <div className="form-group" data-id="task-list">
                    <WorkLoadSRDL
                      planTotal={parseFloat(data.planTotal)}
                      status={sumFTEBody(data.workload, data.owners)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  {data && !data.defaultRole && Object.keys(data).length > 0 ? (
                    <>
                      <div className="form-group role-type">
                        <input
                          type="radio"
                          className="logit"
                          id="single_owner"
                          name="role_type"
                          value="Single owner"
                          disabled={true}
                          defaultChecked={
                            data.ownerRole === "Single owner" ? true : false
                          }
                        />
                        <label for="single_owner" className="inline-label">
                          Single owner
                        </label>

                        <input
                          type="radio"
                          className="logit"
                          id="multiple_owner"
                          name="role_type"
                          value="Multiple owner"
                          disabled={true}
                          defaultChecked={
                            data.ownerRole === "Multiple owners" ? true : false
                          }
                        />
                        <label for="multiple_owner" className="inline-label">
                          Multiple owner
                        </label>

                        <div
                          className="form-group flag"
                          style={{ display: "none" }}
                        >
                          <label>
                            Single owner role can be {/* Circle/ Project */ `${GetAliasesName(teams, "Circles")}/${GetAliasesName(teams, "Project")}`} lead
                          </label>
                        </div>
                      </div>

                      <div className="form-group role-owners">
                        <div className="row">
                          <div className="col-xs-9" style={{ paddingRight: 0 }}>
                            <h4>Owners</h4>
                          </div>
                          <div className="col-xs-3">
                            <h4>FTE</h4>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-9" style={{ paddingRight: 0 }}>
                            {curOwners && curOwners.length > 0
                              ? curOwners.map((e, index) => {
                                return (
                                  <>
                                    {e && Object.keys(e).length > 0 ? (
                                      <div
                                        key={index}
                                        className="report-tool-item"
                                      >
                                        {/* {e.workRole === "External" ? (
                                          <span className="custom-badge blue">
                                            EXT
                                          </span>
                                        ) : null}
                                        {`${e.fname} ${e.lname}`} */}

                                        {e.workRole === "External" ? (
                                          <>
                                            <span
                                              className="custom-badge blue"
                                              style={{
                                                display: "inline-block",
                                                marginBottom: "0",
                                              }}
                                            >
                                              EXT
                                            </span>{" "}
                                            {`${e.fname} ${e.lname}`}
                                          </>
                                        ) : (
                                          `${e.fname} ${e.lname}`
                                        )}
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })
                              : null}
                          </div>
                          <div className="col-xs-3">
                            {curOwners &&
                              curOwners.length > 0 &&
                              curOwners.map((owner, index) => {
                                // Find the corresponding plan value from workload based on ownerId
                                const matchingWorkload = data.workload.find(
                                  (item) => item && item.ownerId === owner._id
                                );

                                // Extract the plan value or use an empty string if it doesn't exist
                                const planValue = matchingWorkload
                                  ? matchingWorkload.plan
                                  : "";

                                return (
                                  <input
                                    key={index}
                                    style={{ margin: "2px 2px 5px 0" }}
                                    type="number"
                                    id={`plan-${index}`}
                                    className="form-control fte"
                                    name={`plan-${index}`}
                                    value={planValue}
                                    onWheel={numberInputOnWheelPreventChange}
                                  />
                                );
                              })}
                          </div>
                        </div>

                        <div
                          className="form-group flag"
                          style={{ display: "none" }}
                        >
                          <label>
                            Roles without owners will be flagged:{" "}
                            <span className="custom-badge red">NO</span>
                          </label>
                        </div>
                      </div>

                      {/* <DefaultRoleOwners /> */}

                      <div className="form-group domains">
                        <h4>{GetAliasesName(teams, "Domains")}</h4>
                        <div className="row">
                          <div className="col-xs-6">
                            <div className="flag">
                              <label>This role owns these {/* domains */GetAliasesName(teams, "Domains").toLowerCase()}:</label>
                            </div>
                          </div>
                          <div
                            className="col-xs-6"
                            style={{ "padding-left": "5px;" }}
                          >
                            <div className="flag">
                              <label>Responsible:</label>
                            </div>
                          </div>
                        </div>
                        <div className="row domain-list"></div>
                        {cuDomains && cuDomains.length > 0
                          ? cuDomains.map((e, index) => {
                            return (
                              <>
                                {e && Object.keys(e).length > 0 ? (
                                  <div key={index} className="row">
                                    <div
                                      className="col-xs-6 choose-domain"
                                      style={{ "padding-right": "0px" }}
                                    >
                                      <div className="report-tool-item">
                                        {e.domainName}
                                      </div>
                                    </div>
                                    <div
                                      className="col-xs-6 pick-owner"
                                      style={{ "padding-left": "5px" }}
                                    >
                                      <div className="report-tool-item">
                                        {e.ownerName}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            );
                          })
                          : null}
                      </div>

                      <div className="form-group member-in">
                        <h4>Member In</h4>
                        <div className="form-group flag">
                          <label>This role is currently member in:</label>
                        </div>
                        <div
                          className="row"
                          style={{ marginRight: 0, marginLeft: 0 }}
                        >
                          {memberIds && memberIds.length > 0
                            ? memberIds.map((e, index) => {
                              return (
                                <div key={index} className="col-xs-12">
                                  {e.memberName}
                                  {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                    <span
                                      style={{
                                        display: "inlineBlock",
                                        marginBottom: "0",
                                      }}
                                      className="custom-badge lightgray person-pill text-red"
                                    >
                                      <span className="text-red">XTD</span>
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })
                            : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {data.defaultRole ? (
                    <div className="form-group role-owners member-in">
                      <div className="row">
                        <div className="col-xs-9">
                          <h4>Owners</h4>
                        </div>
                        <div className="col-xs-3">
                          <h4>FTE</h4>
                        </div>
                      </div>

                      {cumentor &&
                        cumentor.length > 0 &&
                        cumentor.map((data, i) => (
                          <div className="row" key={i}>
                            <div
                              className="col-xs-9"
                              style={{ paddingRight: 0 }}
                            >
                              <div class="report-tool-item">{data.name}</div>
                            </div>
                            <div className="col-xs-3">
                              <div class="report-tool-item">{data.plan}</div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : null}

                  <Documents data={data} type="MY-REPORT" />

                  {data.defaultRole ? (
                    <div className="form-group role-type">
                      <div
                        id="mentor-note"
                        className="form-group default-role-note"
                      >
                        <h4 style={{ margin: 0 }} >
                          This is a GENERIC ROLE!
                        </h4>
                        <a
                          className="more-info"
                          href="https://teamdecoder.tawk.help/article/generic-items"
                          target="_blank"
                          rel="noopener nofollow"
                        >
                          More info
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  <button
                    type="button"
                    onClick={() => historysGet(data._id)}
                    style={
                      historysMessage.length > 0
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    className="btn btn-mytpt-gray history"
                  >
                    <img alt="" src="../images/newicons/history.svg" />
                  </button>
                </div>
                <div className="col-xs-8 text-right">
                  <button
                    type="button"
                    onClick={CloseRoleModal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
