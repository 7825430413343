import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { historyGet } from "../../../../store/actions/historyAction";
import {
  createPeople,
  getPeople,
} from "../../../../store/actions/peopleAction";
import {
  createSkills,
  getSkills,
  updateSkills,
  dropUpdateSkills,
} from "../../../../store/actions/skillAction";
import {
  createRole,
  getRole,
  updateRole,
  dropUpdateRole,
} from "../../../../store/actions/roleAction";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../../store/types/historyType";
import jwtDecode from "jwt-decode";
import WorkLoadSRDL from "../../Body/Model/WorkLoadSRDL";
import { sumFTEBody } from "../../../../HelperFunctions/FTE_cal";
import Objectives from "../../Body/Model/Objectives";
import { numberInputOnWheelPreventChange } from "../../../../HelperFunctions/StopInputScroll";
import { GetAliasesName } from "../../../../HelperFunctions/GetAliasesName";

export default function DomainModal({ data, closeDomain, teams }) {
  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting
  } = useSelector((state) => state.authReport);

  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );

  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const {
    skills,
    cuSkillId,
    nDaoamins,
    skillsMessagee,
    skillError,
    skillsMessage,
  } = useSelector((state) => state.skills);
  const { roles, cuRoleId, dRoles, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );

  const dispatch = useDispatch();

  const reportTokens = localStorage.getItem("reportTokens");
  useEffect(() => {
    if (reportTokens && reportTokens !== "") {
      const myInfo = jwtDecode(reportTokens);
      // console.log(myInfo);
      dispatch(getPeople({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      dispatch(getSkills({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      dispatch(getRole({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      /* dispatch(getLink({ adminId: myInfo.userId, teamId: myInfo.teamId }))
      dispatch(getDomain({ adminId: myInfo.userId, teamId: myInfo.teamId }))
      dispatch(getCircle({ adminId: myInfo.userId, teamId: myInfo.teamId }))
      dispatch(getProject({ adminId: myInfo.userId, teamId: myInfo.teamId }))
      dispatch(getPFMember({ adminId: myInfo.userId, teamId: myInfo.teamId }))
      dispatch(getState({ adminId: myInfo.userId, teamId: myInfo.teamId })) */
    }
  }, [reportTokens]);

  let curStandIn = null;
  if (
    data &&
    data.standIn &&
    data.standIn.length > 0 &&
    peoples &&
    peoples.length > 0
  ) {
    for (let i = 0; i < peoples.length; i++) {
      // console.log(peoples[i]);
      if (peoples[i]._id === data.standIn) {
        curStandIn = peoples[i];
      }
    }
  }

  let curOwnerType = "";
  let curOwner = null;
  if (data && data.owners) {
    if (
      data.owners.type &&
      skills &&
      skills.length > 0 &&
      roles &&
      roles.length > 0
    ) {
      if (data.owners.type === "63d3c91d40c4cf67373922e2") {
        curOwnerType = "Person";
      } else {
        let ownerSkill = skills.find((item) => item._id === data.owners.type);
        if (ownerSkill) {
          curOwnerType = `${ownerSkill.skillName} (${GetAliasesName(teams, "Skills")})`;
        }

        let ownerRole = roles.find((item) => item._id === data.owners.type);
        if (ownerRole) {
          curOwnerType = `${ownerRole.roleName} (${GetAliasesName(teams, "Roles")})`;
        }
      }
    }

    if (
      data.owners.owner &&
      data.owners.owner.length > 0 &&
      peoples &&
      peoples.length > 0
    ) {
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === data.owners.owner) {
          curOwner = peoples[i];
        }
      }
    }
  }
  // console.log(curOwnerType, curOwner);

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  let memberIds = [];
  if (data && members.length > 0) {
    let Members = [...members];
    Members.map((e, index) => {
      let inc = e.coreMembers.Domains.includes(data._id);
      let incs = e.extendedMembers.Domains.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, modelId: data._id, XTD: "" });
      }
      if (incs) {
        memberIds.push({
          memberId: e.memberType,
          modelId: data._id,
          XTD: "XTD",
        });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + "(Circle)";
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + "(Project)";
        }
      }
    }
  }

  const CloseDomainModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeDomain();
  };

  return (
    <div
      id="domainModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable report-modal">
        <form className="form">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header header-blue">
              <h4 className="modal-title">
                <img
                  src="../images/icon-domain.png"
                  style={{
                    width: "17px",
                    marginTop: "-3px",
                    marginRight: "5px",
                  }}
                />
                {/* DOMAIN */ GetAliasesName(teams, "Domains").toUpperCase()}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div
                        name="domain_name"
                        className="form-control tool-name"
                        placeholder="Domain name"
                      >
                        {data.domainName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    {data.purpose && (
                      <TextareaAutosize
                        cacheMeasurements
                        type="text"
                        value={data.purpose}
                        className="form-control purpose logit"
                        name="domain_purpose"
                        placeholder="+ Add purpose"
                        disabled
                        readOnly
                      />
                    )}
                  </div>

                  <div className="form-group" data-id="task-list">
                    <h4>Tasks</h4>
                    {data.tasks && data.tasks.length > 0 && (
                      <div className="row task-list">
                        {data.tasks && data.tasks.length > 0
                          ? data.tasks.map((r, tindex) => {
                              return (
                                <div
                                  className="col-xs-12 task"
                                  key={tindex}
                                  style={{ position: "relative" }}
                                >
                                  <TextareaAutosize
                                    cacheMeasurements
                                    id={`task${tindex}`}
                                    type="text"
                                    placeholder="+ Add task"
                                    name="tasks"
                                    className="form-control logit"
                                    value={r}
                                    disabled
                                  >
                                    <span className="fa fa-circle"> </span>
                                  </TextareaAutosize>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    )}
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={data} skill={data} type="REPORT_DATA"/>
                  </div>

                  <div className="form-group" data-id="task-list">
                    <WorkLoadSRDL
                      planTotal={parseFloat(data.planTotal)}
                      status={sumFTEBody(data.workload, [data.owners.owner])}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <h4>Owner</h4>
                    <div className="row">
                      <div className="col-xs-5">
                        <div className="flag">
                          <label>Type:</label>
                        </div>
                      </div>
                      <div className="col-xs-5">
                        <div className="flag">
                          <label>Owner:</label>
                        </div>
                      </div>
                      <div className="col-xs-2">
                        <div className="flag">
                          <label>FTE</label>
                        </div>
                      </div>
                    </div>
                    <div className="row domain-owner">
                      <div className="col-xs-5" style={{ paddingRight: 0 }}>
                        {curOwnerType && curOwnerType !== "" ? (
                          <div className="report-tool-item">{curOwnerType}</div>
                        ) : null}
                      </div>
                      <div className="col-xs-5" style={{ paddingRight: 0 }}>
                        {curOwner ? (
                          <div className="report-tool-item">{`${curOwner.fname} ${curOwner.lname}`}</div>
                        ) : null}
                      </div>
                      <div className="col-xs-2">
                        <input
                          type="number"
                          // id={`plan-${index}`}
                          className="form-control fte"
                          // name={`plan-${index}`}
                          value={
                            (data &&
                              data.workload &&
                              data.workload.length > 0 &&
                              data.workload[0].plan) ||
                            ""
                          }
                          onWheel={numberInputOnWheelPreventChange}
                        />
                      </div>
                      <div className="col-xs-12">
                        <div
                          className="form-group flag"
                          style={{ display: "none" }}
                        >
                          <label>
                            Domains without owners will be flagged:{" "}
                            <span className="custom-badge red">NO</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group domain-stand-in">
                      <h4>Stand In</h4>
                      {curStandIn ? (
                        <div className="report-tool-item">
                          {curStandIn.workRole === "External" ? (
                            <span className="custom-badge blue">EXT</span>
                          ) : null}
                          {`${curStandIn.fname} ${curStandIn.lname}`}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group member-in">
                      <h4>Member In</h4>
                      <div className="flag">
                        <label>This {/* domain */GetAliasesName(teams, "Domains").toLowerCase()} is currently member in:</label>
                      </div>
                      {memberIds && memberIds.length > 0 && (
                        <div
                          className="row"
                          style={{ marginRight: 0, marginLeft: 0 }}
                        >
                          {memberIds && memberIds.length > 0
                            ? memberIds.map((e, index) => {
                                return (
                                  <div key={index} className="col-xs-12">
                                    {e.memberName}
                                    {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                      <span
                                        style={{
                                          display: "inlineBlock",
                                          marginBottom: "0",
                                        }}
                                        className="custom-badge lightgray person-pill text-red"
                                      >
                                        <span className="text-red">XTD</span>
                                      </span>
                                    ) : null}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <h4>Tags</h4>
                      {data.tags && data.tags.length > 0 && (
                        <div
                          id="tagator_"
                          className="tagator_element options-hidden"
                          style={{
                            padding: "1px 2px",
                            flexGrow: "0",
                            position: "relative",
                          }}
                        >
                          <div className="tagator_tags">
                            {data.tags && data.tags.length > 0
                              ? data.tags.map((e, index) => {
                                  return (
                                    <div key={index} className="tagator_tag">
                                      {e}
                                      <div style={{ clear: "both" }}></div>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  <button
                    type="button"
                    onClick={() => historysGet(data._id)}
                    style={
                      historysMessage.length > 0
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    className="btn btn-mytpt-gray history"
                  >
                    {/* <i className="fa fa-history"></i> */}
                    <img alt="" src="../images/newicons/history.svg" />
                  </button>
                </div>
                <div className="col-xs-8 text-right">
                  <button
                    type="button"
                    onClick={CloseDomainModal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>

                  {/* <!-- <button type="button" data-tippy-content="Save And Close" className="btn btn-mytpt save-close" data-modal_id="domainModal"><i className="fa fa-check"></i></button> --> */}
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
