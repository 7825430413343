import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getMember } from "../../../store/actions/memberAction";
import { getPFMember } from "../../../store/actions/pfmemberAction";
import { createPeople, getPeople } from "../../../store/actions/peopleAction";
import {
  createSkills,
  getSkills,
  updateSkills,
  dropUpdateSkills,
} from "../../../store/actions/skillAction";
import {
  createRole,
  getRole,
  updateRole,
  dropUpdateRole,
} from "../../../store/actions/roleAction";
import {
  createLink,
  getLink,
  updateLink,
  dropUpdateLink,
} from "../../../store/actions/linkAction";
import {
  createDomain,
  getDomain,
  updateDomain,
  dropUpdateDomain,
} from "../../../store/actions/domainAction";
import {
  createProject,
  getProject,
} from "../../../store/actions/projectAction";
import { createCircle, getCircle } from "../../../store/actions/circleAction";
import {
  createState,
  updateState,
  getState,
} from "../../../store/actions/stateAction";
import jwtDecode from "jwt-decode";
import { GetAliasesName } from "../../../HelperFunctions/GetAliasesName";

export default function AllReport({
  setData,
  openReportPeople,
  closReportPeople,
  openReportSkill,
  closReportSkill,
  openReportRole,
  closReportRole,
  openReportDomain,
  closReportDomain,
  openReportLink,
  closReportLink,
  openReportCircle,
  closReportCircle,
  openReportProject,
  closReportProject,
  filterReport,
  setFilterReport,
  openInfo,
  closeInfo,
  isInfos,
  teams
}) {
  const dispatch = useDispatch();

  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting,
  } = useSelector((state) => state.authReport);
  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const {
    skills,
    cuSkillId,
    nDaoamins,
    skillsMessagee,
    skillError,
    skillsMessage,
  } = useSelector((state) => state.skills);
  const { roles, cuRoleId, dRoles, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );
  const {
    domains,
    cuDomainId,
    dDomains,
    domainMessage,
    domainMessagee,
    domainError,
  } = useSelector((state) => state.domains);
  const { links, cuLinkId, linkMessagee, linkError } = useSelector(
    (state) => state.links
  );
  const { circles, dCircles, cuCircleId, circleMessagee, circleError } =
    useSelector((state) => state.circles);
  const { projects, cuProjectId, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { pfmembers, pfmemberMessagee, pfmemberError, pfmemberMessage } =
    useSelector((state) => state.pfmembers);
  const {
    meetings,
    meetingsMessage,
    meetingId,
    meetingsMessagee,
    meetingsError,
  } = useSelector((state) => state.meetings);
  const { noteData, noteMessage, noteMessagee, noteError } = useSelector(
    (state) => state.notes
  );
  const { circleTools, toolSuccessMessage, toolErrors } = useSelector(
    (state) => state.tools
  );
  const {
    states,
    statesLM,
    cuStateId,
    statesMessagee,
    statesError,
    statesMessage,
  } = useSelector((state) => state.states);

  const reportTokens = localStorage.getItem("reportTokens");
  useEffect(() => {
    if (reportTokens && reportTokens !== "") {
      const myInfo = jwtDecode(reportTokens);
      // console.log(myInfo);
      // dispatch(getPeople({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      // dispatch(getSkills({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      // dispatch(getRole({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      // dispatch(getLink({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      // dispatch(getDomain({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      // dispatch(getCircle({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      // dispatch(getProject({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      // dispatch(getPFMember({ adminId: myInfo.userId, teamId: myInfo.teamId }))
      dispatch(getState({ adminId: myInfo.userId, teamId: myInfo.teamId }))
    }
  }, [reportTokens]);

  let setReportPeopleData = (data) => {
    setData(data);
    openReportPeople();
  };

  let setReportSkillData = (data) => {
    setData(data);
    openReportSkill();
  };

  let setReportRoleData = (data) => {
    setData(data);
    openReportRole();
    // console.log(data);
  };

  let setReportDomainData = (data) => {
    setData(data);
    openReportDomain();
    // console.log(data);
  };

  let setReportLinkData = (data) => {
    setData(data);
    openReportLink();
  };

  let setReportCircleData = (data) => {
    setData(data);
    openReportCircle();
  };

  let setReportProjectData = (data) => {
    setData(data);
    openReportProject();
  };

  const [noteSaveData, setNoteSaveData] = useState({
    People: "",
    Skills: "",
    Roles: "",
    Domains: "",
    Links: "",
    Circles: "",
    Projects: "",
  });

  useEffect(() => {
    let obj = {
      People: "",
      Skills: "",
      Roles: "",
      Domains: "",
      Links: "",
      Circles: "",
      Projects: "",
    };
    if (noteData && noteData.length > 0) {
      for (let i = 0; i < noteData.length; i++) {
        // console.log(noteData[i].noteCard)
        obj[noteData[i].noteCard] = noteData[i].noteMessage;
      }
      setNoteSaveData(obj);
    }
  }, [noteData]);

  // hide info created by ranjit
  const hideInfo = (i) => {
    const updatedData = [...filterReport];
    updatedData[i].isChecked = !updatedData[i].isChecked;
    setFilterReport(updatedData);
  };

  document.body.style = "";
  document.body.classList.remove("no-app-pages");
  document.body.classList.remove(`purpose-tree`);

  const checkIsExternal = (e, cardType) => {
    // console.log(e, cardType);

    let owners = [];
    switch (cardType) {
      case "skill":
      case "role":
        owners = e.owners;
        break;

      case "domain":
        if (e.owners) {
          owners = e.owners.owner;
        }
        break;

      case "link":
        owners = e.owner;
        break;

      default:
        break;
    }

    // console.log(peoples, owners);

    let external = true;
    if (owners && owners.length > 0) {
      for (let i = 0; i < owners.length; i++) {
        if (owners[i] && owners[i] !== undefined) {
          const ownerPerson = peoples.filter((item) => item._id === owners[i]);
          if (
            ownerPerson &&
            ownerPerson[0] &&
            ownerPerson[0].workRole !== "External"
          ) {
            // console.log(ownerPerson[0], ownerPerson[0].fname, ownerPerson[0].workRole, ownerPerson[0].workRole === 'External');
            external = false;
            break;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }

    return external;
  };

  const whoIsOwnerType = (e) => {
    // console.log(e, skills);
    if (e.owners && e.owners.type && e.owners.type.length > 0) {
      for (let i = 0; i < skills.length; i++) {
        if (e.owners.type === skills[i]._id) {
          return {
            cardId: skills[i]._id,
            name: skills[i].skillName + ` (${GetAliasesName(teams, "Skills")})`,
            type: "Skill",
          };
        }
      }

      for (let i = 0; i < roles.length; i++) {
        if (e.owners.type === roles[i]._id) {
          return {
            cardId: roles[i]._id,
            name: roles[i].roleName + ` (${GetAliasesName(teams, "Roles")})`,
            type: "Role",
          };
        }
      }
    }
    return {};
  };

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  const removeDuplicates = (array) => {
    const uniqueArray = [];

    array.forEach((obj) => {
      if (!uniqueArray.some((uniqueObj) => areObjectsEqual(obj, uniqueObj))) {
        uniqueArray.push(obj);
      }
    });

    return uniqueArray;
  };

  const getPills = (e) => {
    // console.log(e._doc, members);
    var pills = [];
    let memberObject = members.filter((member) => member.memberType === e._id);

    // console.log(memberObject);

    if (memberObject[0] && memberObject[0].coreMembers) {
      let coreMembers = memberObject[0].coreMembers;
      // console.log(reportInfo.peopleId, coreMembers);

      if (coreMembers.People.length > 0) {
        coreMembers.People.map((member, index) => {
          if (reportInfo.peopleId === member) {
            pills.push({ type: "person", name: "Added as person", xtd: false });
          }
        });
      }

      // console.log(reportSkill);

      if (coreMembers.Skills.length > 0) {
        coreMembers.Skills.map((member, index) => {
          // console.log(member)
          for (let i = 0; i < reportSkill.length; i++) {
            if (
              reportSkill[i] &&
              reportSkill[i].owners &&
              reportSkill[i].owners.length > 0 &&
              reportSkill[i].owners.includes(reportInfo.peopleId) &&
              member === reportSkill[i]._id
            ) {
              pills.push({
                type: "skill",
                name: `${reportSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                xtd: false,
              });
            }
          }
        });
      }

      if (coreMembers.Roles.length > 0) {
        coreMembers.Roles.map((member, index) => {
          for (let i = 0; i < reportRole.length; i++) {
            if (
              reportRole[i] &&
              reportRole[i].owners &&
              reportRole[i].owners.length > 0 &&
              reportRole[i].owners.includes(reportInfo.peopleId) &&
              member === reportRole[i]._id
            ) {
              pills.push({
                type: "role",
                name: `${reportRole[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                xtd: false,
              });
            }
          }
        });
      }

      if (coreMembers.Domains.length > 0) {
        coreMembers.Domains.map((member, index) => {
          for (let i = 0; i < reportDomain.length; i++) {
            if (
              reportDomain[i] &&
              reportDomain[i].owners &&
              reportDomain[i].owners.owner &&
              reportDomain[i].owners.owner.length > 0 &&
              reportDomain[i].owners.owner.includes(reportInfo.peopleId) &&
              member === reportDomain[i]._id
            ) {
              pills.push({
                type: "domain",
                name: `${reportDomain[i].domainName} (${GetAliasesName(teams, "Domains")})`,
                xtd: false,
              });
            }
          }
        });
      }

      if (coreMembers.Links.length > 0) {
        coreMembers.Links.map((member, index) => {
          for (let i = 0; i < reportLink.length; i++) {
            if (
              reportLink[i] &&
              reportLink[i].owner &&
              reportLink[i].owner.length > 0 &&
              reportLink[i].owner.includes(reportInfo.peopleId) &&
              member === reportLink[i]._id
            ) {
              pills.push({
                type: "link",
                name: `${reportLink[i].linkName} (${GetAliasesName(teams, "Links")})`,
                xtd: false,
              });
            }
          }
        });
      }

      let extendedMembers = memberObject[0].extendedMembers;
      // console.log(reportInfo.peopleId, extendedMembers);
      if (extendedMembers.People.length > 0) {
        extendedMembers.People.map((member, index) => {
          if (reportInfo.peopleId === member) {
            pills.push({ type: "person", name: "Added as person", xtd: true });
          }
        });
      }

      if (extendedMembers.Skills.length > 0) {
        extendedMembers.Skills.map((member, index) => {
          for (let i = 0; i < reportSkill.length; i++) {
            if (
              reportSkill[i] &&
              reportSkill[i].owners &&
              reportSkill[i].owners.length > 0 &&
              reportSkill[i].owners.includes(reportInfo.peopleId) &&
              member === reportSkill[i]._id
            ) {
              pills.push({
                type: "skill",
                name: `${reportSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                xtd: true,
              });
            }
          }
        });
      }

      if (extendedMembers.Roles.length > 0) {
        extendedMembers.Roles.map((member, index) => {
          for (let i = 0; i < reportRole.length; i++) {
            if (
              reportRole[i] &&
              reportRole[i].owners &&
              reportRole[i].owners.length > 0 &&
              reportRole[i].owners.includes(reportInfo.peopleId) &&
              member === reportRole[i]._id
            ) {
              pills.push({
                type: "role",
                name: `${reportRole[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                xtd: true,
              });
            }
          }
        });
      }

      if (extendedMembers.Domains.length > 0) {
        extendedMembers.Domains.map((member, index) => {
          for (let i = 0; i < reportDomain.length; i++) {
            if (
              reportDomain[i] &&
              reportDomain[i].owners &&
              reportDomain[i].owners.owner &&
              reportDomain[i].owners.owner.length > 0 &&
              reportDomain[i].owners.owner.includes(reportInfo.peopleId) &&
              member === reportDomain[i]._id
            ) {
              pills.push({
                type: "domain",
                name: `${reportDomain[i].domainName} (${GetAliasesName(teams, "Domains")})`,
                xtd: true,
              });
            }
          }
        });
      }

      if (extendedMembers.Links.length > 0) {
        extendedMembers.Links.map((member, index) => {
          for (let i = 0; i < reportLink.length; i++) {
            if (
              reportLink[i] &&
              reportLink[i].owner &&
              reportLink[i].owner.length > 0 &&
              reportLink[i].owner.includes(reportInfo.peopleId) &&
              member === reportLink[i]._id
            ) {
              pills.push({
                type: "link",
                name: `${reportLink[i].linkName} (${GetAliasesName(teams, "Links")})`,
                xtd: true,
              });
            }
          }
        });
      }
    }

    if (e.lead) {
      // console.log(e.circleName, e.lead, reportInfo.peopleId)
      if (e.lead === reportInfo.peopleId) {
        // console.log('lead is the person in focus')
        pills.push({ type: "direct-lead", name: `LEAD` });
      } else {
        reportSkill.forEach((rs) => {
          if (rs._id === e.lead && rs.owners.includes(reportInfo.peopleId)) {
            pills.push({ type: "indirect-lead", name: `LEAD` });
          }
        });

        reportRole.forEach((rr) => {
          if (rr._id === e.lead && rr.owners.includes(reportInfo.peopleId)) {
            pills.push({ type: "indirect-lead", name: `LEAD` });
          }
        });
      }
    }
    // console.log(e.circleName, pills)

    const uniqueArray = removeDuplicates(pills);

    // console.log(uniqueArray)
    return uniqueArray;
  };

  //===================================================PEOPLE REPORT=============================================

  const PRPeople = () => (
    <div className="board-col " data-board_id="people">
      <div className="list list-bg-yellow" data-list_id="people">
        <div className="dropdown" style={{ position: "static" }}>
          <h4 className="list-title">
            <img alt="" src="../images/icon-people.png" /> {/* People */}{GetAliasesName(teams, "People")}
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("People")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(0)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.People ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.People}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {peoples && peoples.length > 0
            ? peoples.map((e, index) => {
                return (
                  <div key={index}>
                    {e && Object.keys(e).length > 0 ? (
                      <div
                        className="card readonly"
                        data-list_id="people"
                        data-history=""
                      >
                        {e.workRole === "External" ? (
                          <span className="custom-badge blue ext-owner-pill">
                            EXT
                          </span>
                        ) : null}

                        <div className="static-relations">
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, indexs) => (
                                <span
                                  key={indexs}
                                  className="custom-badge green tags"
                                  data-tag="Apple"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          onClick={() => setReportPeopleData(e)}
                          className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRSkill = () => (
    <div className="board-col" data-board_id="skills">
      <div className="list list-bg-blue" data-list_id="skills">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-skill.png" /> {/* SKILL */}{GetAliasesName(teams, "Skills")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Skills")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              {/* <!-- <li role="presentation"><a role="menuitem" href="#" data-toggle="modal" data-target="#skillsModal">Add new {GetAliasesName(teams,"Skills").toLowerCase()}</a></li> --> */}
              <li role="presentation" onClick={() => hideInfo(1)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>
        <div className="card-list scrollbar">
          {noteSaveData.Skills ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Skills}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {skills && skills.length > 0
            ? skills.map((e, index) => {
                let isExternal = checkIsExternal(e, "skill");

                return (
                  <div key={index} className="card readonly" data-history="">
                    {isExternal ? (
                      <span className="custom-badge blue ext-owner-pill">
                        EXT
                      </span>
                    ) : null}

                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, rindex) => (
                            <span
                              key={rindex}
                              className="custom-badge green tags"
                              data-tag="Apple"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      onClick={() => setReportSkillData(e)}
                      className="card-edit"
                    >
                      {e.skillName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRRole = () => (
    <div className="board-col" data-board_id="roles">
      <div className="list list-bg-blue" data-list_id="roles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-role.png" /> {/* Roles */}{GetAliasesName(teams, "Roles")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Roles")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(2)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Roles ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Roles}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {roles.length > 0
            ? roles.map((e, index) => {
                let isExternal = checkIsExternal(e, "role");

                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="roles"
                  >
                    <span className="custom-badge red-o direct-lead hide">
                      LEAD
                    </span>
                    {isExternal ? (
                      <span className="custom-badge blue ext-owner-pill">
                        EXT
                      </span>
                    ) : null}
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                              data-tag="Apple"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      onClick={() => setReportRoleData(e)}
                      className="card-edit"
                    >
                      {e.roleName}
                    </a>
                  </div>
                );
              })
            : null}

          {dRoles.length > 0
            ? dRoles.map((e, index) => {
                let isExternal = checkIsExternal(e, "role");
                return (
                  <div
                    key={index}
                    className="card default-role readonly"
                    data-list_id="roles"
                  >
                    <span className="custom-badge red-o direct-lead hide">
                      LEAD
                    </span>
                    {isExternal ? (
                      <span className="custom-badge blue ext-owner-pill">
                        EXT
                      </span>
                    ) : null}
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                              data-tag="Apple"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      onClick={() => setReportRoleData(e)}
                      className="card-edit"
                    >
                      {e.roleName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRDomain = () => (
    <div className="board-col" data-board_id="domain">
      <div className="list list-bg-blue" data-list_id="domains">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-domain.png" /> {/* Domains */}{GetAliasesName(teams, "Domains")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Domains")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(3)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Domains ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Domains}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {domains.length > 0
            ? domains.map((e, index) => {
                let isExternal = checkIsExternal(e, "domain");
                let ownerType = whoIsOwnerType(e);
                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="domain"
                    data-history=""
                  >
                    <div className="static-relations">
                      {isExternal ? (
                        <span className="custom-badge blue ext-owner-pill">
                          EXT
                        </span>
                      ) : null}

                      {e.owners &&
                      e.owners.owner &&
                      e.owners.owner.length > 0 ? null : (
                        <span
                          // onClick={() => { toggleEXTnNOCards(e._id, "NO", false, "pill") }}
                          className="custom-badge no-owner-pill red"
                        >
                          NO
                        </span>
                      )}

                      {ownerType && Object.keys(ownerType).length > 0 ? (
                        <span
                          // onClick={() => openSkillAndRoleByDPill(ownerType)}
                          className="custom-badge lightgray"
                        >
                          {ownerType.name}
                        </span>
                      ) : null}

                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                              data-tag="Apple"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      onClick={() => setReportDomainData(e)}
                      className="card-edit"
                    >
                      {e.domainName}
                    </a>
                  </div>
                );
              })
            : null}

          {dDomains.length > 0
            ? dDomains.map((e, index) => {
                let isExternal = checkIsExternal(e, "domain");
                let ownerType = whoIsOwnerType(e);
                return (
                  <div
                    key={index}
                    className="card readonly default-domain"
                    data-list_id="domain"
                    data-history=""
                  >
                    {ownerType && Object.keys(ownerType).length > 0 ? (
                      <span
                        // onClick={() => openSkillAndRoleByDPill(ownerType)}
                        className="custom-badge lightgray"
                      >
                        {ownerType.name}
                      </span>
                    ) : null}

                    <div className="static-relations">
                      {isExternal ? (
                        <span className="custom-badge blue ext-owner-pill">
                          EXT
                        </span>
                      ) : null}

                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                              data-tag="Apple"
                            >
                              {r}
                            </span>
                          ))
                        : null}

                      {e.owners &&
                      e.owners.owner &&
                      e.owners.owner.length > 0 ? null : (
                        <span
                          // onClick={() => { toggleEXTnNOCards(e._id, "NO", false, "pill") }}
                          className="custom-badge no-owner-pill red"
                        >
                          NO
                        </span>
                      )}
                    </div>
                    <a
                      onClick={() => setReportDomainData(e)}
                      className="card-edit"
                    >
                      {e.domainName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRLink = () => (
    <div className="board-col" data-board_id="links">
      <div className="list list-bg-blue" data-list_id="links">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-link.png" /> {/* Links */}{GetAliasesName(teams, "Links")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Links")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(4)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Links ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Links}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {links && links.length > 0
            ? links.map((e, index) => {
                let isExternal = checkIsExternal(e, "link");
                // console.log(769, e)
                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="links"
                    data-history=""
                  >
                    {isExternal ? (
                      <span className="custom-badge blue ext-owner-pill">
                        EXT
                      </span>
                    ) : null}
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              key={index}
                              className="custom-badge green tags"
                              data-tag="Apple"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      onClick={() => setReportLinkData(e)}
                      className="card-edit"
                    >
                      {e.linkName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
  // console.log(dCircles)
  const PRCircle = () => (
    <div className="board-col" data-board_id="circles">
      <div className="list list-bg-red" data-list_id="circles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-circle.png" /> {/* Circles */}{GetAliasesName(teams, "Circles")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Circles")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(5)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Circles ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Circles}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}
          {circles && circles.length > 0
            ? circles.map((e, index) => {
                let greyPills = getPills(e);
                return (
                  <div
                    key={index}
                    className={
                      e && e.defaultCircle
                        ? "card default-circle"
                        : "card readonly"
                    }
                    data-list_id="circles"
                  >
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, rindex) => (
                            <span
                              key={rindex}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}

                      {greyPills && greyPills.length > 0
                        ? greyPills.map((pill, pindex) =>
                            pill.type === "person" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray text-red person-pill"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "skill" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "role" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "domain" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "link" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "direct-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge red-o direct-lead"
                              >
                                {pill.name}
                              </span>
                            ) : pill.type === "indirect-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge gray-o indirect-lead"
                              >
                                {pill.name}
                              </span>
                            ) : null
                          )
                        : null}
                    </div>
                    <div className="relations"></div>
                    <a
                      onClick={() => setReportCircleData(e)}
                      className="card-edit"
                    >
                      {e.circleName}
                    </a>
                  </div>
                );
              })
            : null}

          {dCircles && dCircles.length > 0
            ? dCircles.map((e, index) => {
                let greyPills = getPills(e);
                return (
                  <div
                    key={index}
                    className={
                      e && e.defaultCircle
                        ? "card default-circle"
                        : "card readonly"
                    }
                    data-list_id="circles"
                  >
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, rindex) => (
                            <span
                              key={rindex}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}

                      {greyPills && greyPills.length > 0
                        ? greyPills.map((pill, pindex) =>
                            pill.type === "person" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray text-red person-pill"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "skill" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "role" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "domain" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "link" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "direct-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge red-o direct-lead"
                              >
                                {pill.name}
                              </span>
                            ) : pill.type === "indirect-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge gray-o indirect-lead"
                              >
                                {pill.name}
                              </span>
                            ) : null
                          )
                        : null}
                    </div>
                    <div className="relations"></div>
                    <a
                      onClick={() => setReportCircleData(e)}
                      className="card-edit"
                    >
                      {e.circleName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  const PRProject = () => (
    <div className="board-col" data-board_id="projects">
      <div className="list list-bg-red" data-list_id="projects">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="../images/icon-project.png" /> {/* Projects */}{GetAliasesName(teams, "Projects")}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation" onClick={() => isInfos("Projects")}>
                <a role="menuitem" href="#" className="mytpt-list-info">
                  Info
                </a>
              </li>
              <li role="presentation" onClick={() => hideInfo(6)}>
                <a role="menuitem" href="#" className="hide_list">
                  Hide list
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          {noteSaveData.Projects ? (
            <div className="form-group notes">
              <textarea
                className="notes"
                value={noteSaveData.Projects}
                readOnly={true}
                name="People"
                placeholder="Notes"
                maxLength="100"
              ></textarea>
            </div>
          ) : null}

          {projects && projects.length > 0
            ? projects.map((e, index) => {
                let greyPills = getPills(e);

                return (
                  <div
                    key={index}
                    className="card readonly"
                    data-list_id="projects"
                  >
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, rindex) => (
                            <span
                              key={rindex}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}

                      {greyPills && greyPills.length > 0
                        ? greyPills.map((pill, pindex) =>
                            pill.type === "person" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray text-red person-pill"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "skill" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "role" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "domain" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "link" ? (
                              <span
                                key={pindex}
                                className="custom-badge lightgray"
                              >
                                {pill.xtd ? (
                                  <span className="text-red">XTD</span>
                                ) : null}{" "}
                                {pill.name}
                              </span>
                            ) : pill.type === "direct-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge red-o direct-lead"
                              >
                                {pill.name}
                              </span>
                            ) : pill.type === "indirect-lead" ? (
                              <span
                                key={pindex}
                                className="custom-badge gray-o indirect-lead"
                              >
                                {pill.name}
                              </span>
                            ) : null
                          )
                        : null}
                    </div>
                    <div className="relations"></div>
                    <a
                      onClick={() => setReportProjectData(e)}
                      className="card-edit"
                    >
                      {e.projectName}
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  /* const PRMeeting = () => (
      <div
        className="board-col board-col-meetings default"
        data-board_id="meetings"
      >
        <div className="list default-circle" data-list_id="meetings">
          <div className="dropdown">
            <h4 className="list-title">
              My Meetings
              <a className="list-menu refresh-list hide" href="#">
                <i className="fas fa-sync-alt"></i>
              </a>
            </h4>
          </div>
  
          <div className="card-list scrollbar">
            {reportMeeting && reportMeeting.length > 0
              ? reportMeeting.map((e, index) => {
                return (
                  <div key={index} className="card meeting-card">
                    <p>
                      <span className="custom-badge lightgray">{e.name}</span><br />
                      <span className="fas fa-users"></span>
                      <b> {e.meetingsName}</b>
                    </p>
                    <p>{e.meetingsPurpose}</p>
                    <p>
                      Every {`${e.recurrence} ${e.recurrenceType}`} for {`${e.duration} ${e.recurrenceType}`}</p>
                    {e.XTD ? <p>incl. XTD</p> : null}
                  </div>
                );
              })
              : null}
          </div>
        </div>
      </div>
    ); */

  let reportList = [
    PRPeople,
    PRSkill,
    PRRole,
    PRDomain,
    PRLink,
    PRCircle,
    PRProject,
  ];

  //create by ranjit
  let arr = [];
  for (let i = 0; i < filterReport.length; i++) {
    if (reportList[i] && filterReport[i].isChecked === false) {
      arr.push(reportList[i]);
    }
  }

  // edit by ranjit
  return (
    <section className="board-body">
      <div className="wrap-lists" id="my-report">
        {arr && arr.length > 0
          ? arr.map((Item, index) => {
              return <Item key={index} />;
            })
          : null}
      </div>
    </section>
  );
}
