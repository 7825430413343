import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../../store/types/historyType";
import { historyGet } from "../../../../store/actions/historyAction";
import { sumFTE, sumFTEBody } from "../../../../HelperFunctions/FTE_cal";
import WorkLoadSRDL from "../../Body/Model/WorkLoadSRDL";
import Objectives from "../../Body/Model/Objectives";
import { numberInputOnWheelPreventChange } from "../../../../HelperFunctions/StopInputScroll";
import Documents from "../../Body/Model/Documents";
import { GetAliasesName } from "../../../../HelperFunctions/GetAliasesName";

export default function SkillModal({ data, closeSkill, teams }) {
  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting,
  } = useSelector((state) => state.authReport);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);

  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );

  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();

  let curOwners = [];
  if (
    data &&
    data.owners &&
    data.owners.length > 0 &&
    reportPeople.length > 0
  ) {
    for (let i = 0; i < reportPeople.length; i++) {
      let inc = data.owners.includes(reportPeople[i]._id);
      if (inc) {
        curOwners.push(reportPeople[i]);
      }
    }
  }

  let cuDomains = [];

  if (
    data &&
    data.domains &&
    data.domains.length > 0 &&
    reportPeople.length > 0 &&
    reportDomain.length > 0
  ) {
    for (let i = 0; i < data.domains.length; i++) {
      let cuDomain = {};
      for (let j = 0; j < reportDomain.length; j++) {
        if (data.domains[i].domain === reportDomain[j]._id) {
          cuDomain.domainId = reportDomain[j]._id;
          cuDomain.domainName = reportDomain[j].domainName;
        }
      }
      for (let k = 0; k < reportPeople.length; k++) {
        if (data.domains[i].owner === reportPeople[k]._id) {
          cuDomain.ownerId = reportPeople[k]._id;
          cuDomain.ownerName = `${reportPeople[k].fname} ${reportPeople[k].lname}`;
        }
      }
      if (cuDomain) {
        cuDomains.push(cuDomain);
      }
    }
  }

  const historysGetController = (data) => {
    setToggle(true);
    if (data && historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  let memberIds = [];
  if (data) {
    members.map((e, index) => {
      let inc = e.coreMembers.Skills.includes(data._id);
      let incs = e.extendedMembers.Skills.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, XTD: "" });
      }
      if (incs) {
        memberIds.push({ memberId: e.memberType, XTD: "XTD" });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + "(Circle)";
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + "(Project)";
        }
      }
    }
  }

  const CloseSkillModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeSkill();
  };

  return (
    <div
      id="skillModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable report-modal">
        <form className="form">
          <div className="modal-content">
            <div className="modal-header header-blue">
              <h4 className="modal-title">
                <img
                  src="../images/icon-skill.png"
                  style={{
                    width: "17px",
                    marginTop: "-3px",
                    marginRight: "5px",
                  }}
                />{" "}
                {/* SKILL */}{GetAliasesName(teams, "Skills").toUpperCase()}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="hidden"
                        id="skill_name"
                        name="skill_name"
                        value={data.skillName}
                      />
                      <div name="skill_name" className="form-control tool-name">
                        {data.skillName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    {data.purpose && (
                      <TextareaAutosize
                        // cacheMeasurements
                        type="text"
                        value={data.purpose}
                        className="form-control purpose logit"
                        name="skill_purpose"
                        placeholder="+ Add purpose"
                        disabled
                        readOnly
                      />
                    )}
                  </div>

                  <div className="form-group" data-id="task-list">
                    <h4>Tasks</h4>
                    {data.tasks && data.tasks.length > 0 && (
                      <div className="row task-list">
                        {data.tasks && data.tasks.length > 0
                          ? data.tasks.map((r, tindex) => {
                              return (
                                <div
                                  className="col-xs-12 task"
                                  key={tindex}
                                  style={{ position: "relative" }}
                                >
                                  <TextareaAutosize
                                    // cacheMeasurements
                                    id={`task${tindex}`}
                                    type="text"
                                    placeholder="+ Add task"
                                    name="tasks"
                                    className="form-control logit"
                                    value={r}
                                    disabled
                                  >
                                    <span className="fa fa-circle"> </span>
                                  </TextareaAutosize>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    )}
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={data} skill={data} type="REPORT_DATA" />
                  </div>

                  <div className="form-group" data-id="task-list">
                    <WorkLoadSRDL
                      planTotal={parseFloat(data.planTotal)}
                      status={sumFTEBody(data.workload, data.owners)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group skill-type">
                    <input
                      type="radio"
                      className="logit"
                      disabled={true}
                      defaultChecked={
                        data.ownerType === "Single owner" ? true : false
                      }
                      id="single_owner_skill"
                      name="skill_type"
                      value="Single owner"
                    />
                    <label
                      htmlFor="single_owner_skill"
                      className="inline-label"
                    >
                      Single owner
                    </label>

                    <input
                      type="radio"
                      className="logit"
                      disabled={true}
                      defaultChecked={
                        data.ownerType === "Multiple owners" ? true : false
                      }
                      id="multiple_owner_skill"
                      name="skill_type"
                      value="Multiple owner"
                    />
                    <label
                      htmlFor="multiple_owner_skill"
                      className="inline-label"
                    >
                      Multiple owners
                    </label>

                    <div
                      className="form-group flag"
                      style={{ display: "none" }}
                    >
                      <label>
                        Single owner skill can be Circle/ Project lead
                      </label>
                    </div>
                  </div>

                  <div className="form-group skill-owners">
                    <div className="row">
                      <div className="col-xs-9" style={{ paddingRight: 0 }}>
                        <h4>Owners</h4>
                      </div>
                      <div className="col-xs-3">
                        <h4>FTE</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-9" style={{ paddingRight: 0 }}>
                        {curOwners && curOwners.length > 0
                          ? curOwners.map((e, index) => {
                              return (
                                <>
                                  {e && Object.keys(e).length > 0 ? (
                                    <div
                                      key={index}
                                      className="report-tool-item"
                                    >
                                      {/* {e.workRole &&
                                      e.workRole === "External" ? (
                                      <span className="custom-badge blue">
                                        EXT
                                      </span>
                                    ) : null}
                                    {`${e.fname ? e.fname : ""} ${e.lname ? e.lname : ""}`} */}

                                      {e.workRole &&
                                      e.workRole === "External" ? (
                                        <>
                                          <span
                                            className="custom-badge blue"
                                            style={{
                                              display: "inline-block",
                                              marginBottom: "0",
                                            }}
                                          >
                                            EXT
                                          </span>{" "}
                                          {`${e.fname ? e.fname : ""} ${
                                            e.lname ? e.lname : ""
                                          }`}
                                        </>
                                      ) : (
                                        `${e.fname ? e.fname : ""} ${
                                          e.lname ? e.lname : ""
                                        }`
                                      )}
                                    </div>
                                  ) : null}
                                </>
                              );
                            })
                          : null}
                      </div>

                      <div className="col-xs-3">
                        {curOwners &&
                          curOwners.length > 0 &&
                          curOwners.map((owner, index) => {
                            // Find the corresponding plan value from workload based on ownerId
                            const matchingWorkload = data.workload.find(
                              (item) => item && item.ownerId === owner._id
                            );

                            // Extract the plan value or use an empty string if it doesn't exist
                            const planValue = matchingWorkload
                              ? matchingWorkload.plan
                              : "";

                            return (
                              <input
                                key={index}
                                style={{ margin: "2px 2px 5px 0" }}
                                type="number"
                                id={`plan-${index}`}
                                className="form-control fte"
                                name={`plan-${index}`}
                                value={planValue}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="form-group domains">
                    <h4>{GetAliasesName(teams, "Domains")}</h4>
                    <div className="row" style={{ direction: "flex" }}>
                      <div className="col-xs-6">
                        <div className="flag">
                          <label>This skill own these {/* domains */ GetAliasesName(teams, "Domains").toLowerCase()}:</label>
                        </div>
                      </div>
                      <div className="col-xs-6" style={{ paddingLeft: "5px" }}>
                        <div className="flag">
                          <label>Responsible:</label>
                        </div>
                      </div>
                    </div>
                    <div className="row domain-list"></div>
                    {cuDomains && cuDomains.length > 0
                      ? cuDomains.map((e, index) => {
                          return (
                            <div key={index} className="row">
                              <div
                                className="col-xs-6 choose-domain"
                                style={{ "padding-right": "0px" }}
                              >
                                <div className="report-tool-item">
                                  {e.domainName}
                                </div>
                              </div>
                              <div
                                className="col-xs-6 pick-owner"
                                style={{ "padding-left": "5px" }}
                              >
                                <div className="report-tool-item">
                                  {e.ownerName}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>

                  <div className="form-group member-in">
                    <h4>Member In</h4>
                    <div className="flag">
                      <label>This {/* skill */GetAliasesName(teams, "Skills").toLowerCase()} is currently member in:</label>
                    </div>
                    <div
                      className="row"
                      style={{ marginRight: 0, marginLeft: 0 }}
                    >
                      {memberIds && memberIds.length > 0
                        ? memberIds.map((e, index) => {
                            return (
                              <div key={index} className="col-xs-12">
                                {e.memberName}
                                {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                  <span
                                    style={{
                                      display: "inlineBlock",
                                      marginBottom: "0",
                                    }}
                                    className="custom-badge lightgray person-pill text-red"
                                  >
                                    <span className="text-red">XTD</span>
                                  </span>
                                ) : null}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>

                  <Documents data={data} type="MY-REPORT" />
                  <div className="form-group">
                    <h4>Tags</h4>

                    <div className="tagator_tags">
                      {data.tags && data.tags.length > 0
                        ? data.tags.map((e, index) => {
                            return (
                              <div key={index} className="tagator_tag">
                                {e}
                                <div style={{ clear: "both" }}></div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  <button
                    type="button"
                    onClick={() => historysGetController(data._id)}
                    style={
                      historysMessage.length > 0
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    className="btn btn-mytpt-gray history"
                  >
                    <img alt="" src="../images/newicons/history.svg" />
                  </button>
                </div>
                <div className="col-xs-8 text-right">
                  <button
                    type="button"
                    onClick={CloseSkillModal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
